import dayjs from 'dayjs'
import { CurrencyInput } from 'src/components/Currency'
import { DateField } from 'src/components/DateField'
import { DatePicker } from 'src/components/DateInput'
import { FormItem } from 'src/components/FormItem'

export function PromotionForm() {
  return (
    <>
      <FormItem label="promotions.fields.item" name="item" hidden />

      <FormItem label="promotions.fields.startDate" name="startDate">
        <StartDate />
      </FormItem>

      <FormItem label="promotions.fields.endDate" name="endDate" required>
        <DatePicker />
      </FormItem>

      <FormItem label="promotions.fields.price" name="price" required>
        <CurrencyInput />
      </FormItem>
    </>
  )
}
type Props = {
  value?: string
  onChange?: () => void
}
function StartDate(props: Props) {
  const { value } = props
  return <DateField value={dayjs(value)} />
}
