import {
  Upload,
  Typography,
  notification,
  type UploadProps,
  type RcFile,
} from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import filesize from 'filesize'
import Fraction from 'fraction.js'
import { extension } from 'mime-types'
import { cleanHydraId } from 'src/adapters/DataProvider'
import { useHttpClient } from 'src/adapters/HTTPClient'
import { getImageDimension } from 'src/libs/getImageDimension'
import { resizeImage } from 'src/libs/resizeImage'
import { useMinioFileUpload } from 'src/libs/useMinioFile'
import type { IriReference } from 'src/types/api'
import type { UploadFile } from 'src/types/api/extendedTypes'

import { FormItem } from './FormItem'

import './style.less'

const { Text, Title } = Typography

type UploadDocumentProps = {
  resourceId: string
  maxFiles?: number
  maxFileSize?: number
  maxWidth?: number
  maxHeight?: number
  ratio?: Fraction
  single?: boolean
  label?: string
  documentInfo?: string
  required?: boolean
}

export function DocumentUpload(props: UploadDocumentProps) {
  const { single, maxFiles, label, required } = props

  const translate = useTranslate()
  return (
    <>
      <FormItem
        label={label ?? 'components.documentUpload.documents'}
        name={single ? 'document' : 'documents'}
        rules={[
          {
            required,
            message: translate('validation.required'),
          },
        ]}
      >
        <DocumentInput
          {...props}
          maxFiles={single ? 1 : maxFiles}
          required={required}
        />
      </FormItem>
    </>
  )
}

type DocumentInputProps = {
  value?: UploadFile[]
  onChange?: (value?: UploadFile[]) => void
} & UploadDocumentProps

function DocumentInput(props: DocumentInputProps) {
  const {
    value,
    onChange,
    resourceId,
    maxFiles = 10,
    maxFileSize = 20000000,
    maxHeight,
    maxWidth,
    ratio,
    documentInfo,
  } = props
  const translate = useTranslate()

  const httpClient = useHttpClient()
  const upload = useMinioFileUpload()

  async function remove(fileName: string, bucketId: string) {
    return httpClient.delete(`/minio/remove_file/${fileName}/${bucketId}`)
  }

  const files = value?.filter?.((file) => !file.toDelete) ?? []
  const canAddFile = files.length < maxFiles

  const uploadProps: UploadProps = {
    fileList: files,
    accept: 'image/png, image/jpeg',
    openFileDialogOnClick: canAddFile,
    async beforeUpload(file) {
      if (!['image/jpeg', 'image/png'].includes(file.type)) {
        notification.error({
          message: translate('components.documentUpload.errors.invalidFile'),
          description: translate(
            'components.documentUpload.errors.incorrectType',
          ),
        })
        return Upload.LIST_IGNORE
      }
      if (maxFileSize && file.size > maxFileSize) {
        notification.info({
          message: translate('components.documentUpload.errors.invalidFile'),
          description: translate(
            'components.documentUpload.errors.excessSize',
            { maxSize: filesize(maxFileSize, { locale: true }) },
          ),
        })
        return Upload.LIST_IGNORE
      }
      if (ratio || maxHeight || maxWidth) {
        const { width, height } = await getImageDimension(file)

        if (ratio) {
          const imageRatio = new Fraction(width, height).simplify()
          if (!imageRatio.equals(ratio)) {
            notification.info({
              message: translate(
                'components.documentUpload.errors.invalidFile',
              ),
              description: translate(
                'components.documentUpload.errors.wrongRatio',
                {
                  validRatio: ratio.toFraction(),
                  currentRatio: imageRatio.toFraction(),
                },
              ),
            })
            return Upload.LIST_IGNORE
          }
        }

        if (
          (maxWidth && width > maxWidth) ||
          (maxHeight && height > maxHeight)
        ) {
          notification.info({
            message: translate(
              'components.documentUpload.info.fileNeedProcessing',
            ),
            description: translate(
              'components.documentUpload.info.excessDimension',
              {
                maxWidth,
                maxHeight,
              },
            ),
          })
        }
      }
    },

    onChange(info) {
      const { file: uploadedFile, fileList } = info
      if (fileList.length > maxFiles) {
        return
      }

      const ext =
        (uploadedFile as UploadFile).extension ??
        extension((uploadedFile as RcFile).type)
      const fileName = `${uploadedFile.uid}.${ext}`

      onChange?.(
        fileList.map((file) => {
          if (file.uid === uploadedFile.uid) {
            return {
              ...uploadedFile,
              bucketId: resourceId,
              name: fileName,
              size: file.size,
              extension: ext || undefined,
              mimeType: file.type,
              exists: false,
            }
          }
          return file as UploadFile
        }),
      )
    },

    async customRequest(requestOption) {
      const { file, onProgress, onSuccess, onError } = requestOption
      const rcFile = file as RcFile

      const ext = extension(rcFile.type)
      const fileName = `${rcFile.uid}.${ext}`

      let resizedFile
      if (rcFile.size > 1000000) {
        resizedFile = await resizeImage(rcFile)
      }

      try {
        const res = await upload(
          fileName,
          resizedFile ?? rcFile,
          resourceId,
          onProgress,
        )
        onSuccess?.(res.data)
      } catch (error) {
        onError?.(error as any)
      }
    },

    async onRemove(fileToRemove) {
      const { bucketId, name, exists, uid } = fileToRemove as UploadFile
      if (exists) {
        onChange?.(
          value?.map((file) => {
            if (file.uid === uid) {
              return { ...file, toDelete: true }
            }
            return file
          }),
        )
        return
      }

      try {
        await remove(name, bucketId ?? resourceId)
        onChange?.(value?.filter((file) => file.uid !== uid))
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
  }

  return (
    <div className="AteUpload">
      <Upload.Dragger {...uploadProps} listType="picture-card">
        <Title level={5} type={!canAddFile ? 'danger' : undefined}>
          {translate(
            `components.documentUpload.${
              canAddFile ? 'selectFile' : 'cantSelectFile'
            }`,
          )}
        </Title>
        {canAddFile && (
          <Text>
            {translate(
              documentInfo ?? 'components.documentUpload.fileRestriction',
              { maxSize: filesize(maxFileSize, { locale: true }) },
            )}
          </Text>
        )}
      </Upload.Dragger>
    </div>
  )
}

export function hydrateDocuments(
  documents: IriReference[],
  apiUrl: string,
): UploadFile[] {
  return documents.map((documentId) => {
    return {
      uid: documentId,
      name: documentId,
      url: `${apiUrl}/minio/document/${cleanHydraId(documentId)}`,
      exists: true,
    }
  })
}
