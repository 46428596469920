import { useForm, Create, Form } from '@pankod/refine-antd'
import { useOne } from '@pankod/refine-core'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { toHydraId } from 'src/adapters/DataProvider'
import { useParsedLocation } from 'src/libs/useParsedLocation'
import type { Item, Promotion } from 'src/types/api'
import { ResourcePathEnum } from 'src/types/api'

import { PromotionForm } from './PromotionForm'

export function CreateView() {
  const navigate = useNavigate()
  const { query } = useParsedLocation<{ product: string }>()
  const { product } = query

  if (!product) throw new Error('pages.error.productMissing')

  const { formProps, saveButtonProps, form } = useForm<Promotion>({
    redirect: false,
    onMutationSuccess() {
      navigate(-1)
    },
  })

  const { data, isLoading } = useOne<Item>({
    resource: ResourcePathEnum.items,
    id: product,
    queryOptions: {
      useErrorBoundary: true,
    },
  })

  useEffect(() => {
    if (data?.data.price) form.setFieldsValue({ price: data?.data.price })
  }, [data?.data.price, form])

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={isLoading}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          item: toHydraId(ResourcePathEnum.items, product),
        }}
      >
        <PromotionForm />
      </Form>
    </Create>
  )
}
