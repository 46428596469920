import {
  EditButton as RefineEditButton,
  ShowButton as RefineShowButton,
} from '@pankod/refine-antd'

import { cleanHydraId } from '../adapters/DataProvider'

export function EditButton(props: Parameters<typeof RefineEditButton>[0]) {
  return (
    <RefineEditButton
      {...props}
      recordItemId={
        props.recordItemId && cleanHydraId(String(props.recordItemId))
      }
    />
  )
}

export function ShowButton(props: Parameters<typeof RefineShowButton>[0]) {
  return (
    <RefineShowButton
      {...props}
      recordItemId={
        props.recordItemId && cleanHydraId(String(props.recordItemId))
      }
    />
  )
}
