import { colors } from '@alltime-eat/design-tokens'
import { Input, InputNumber, Typography, Form } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import Fraction from 'fraction.js'
import type L from 'leaflet'
import { useCallback, useRef } from 'react'
import { Circle, Marker, useMapEvent } from 'react-leaflet'
import { isDefined } from 'remeda'
import type { Ad } from 'src/types/api'

import { DocumentUpload } from '../../components/DocumentUpload'
import { FormItem } from '../../components/FormItem'
import { Map, MarkerIcon } from '../../components/Map'

import './style.less'

type Props = {
  id: string
  center?: [number, number]
}
export function AdForm(props: Props) {
  const { id, center } = props
  const translate = useTranslate()

  return (
    <>
      <FormItem
        label="ads.fields.label"
        name="label"
        rules={[
          {
            required: true,
            message: translate('validation.required'),
          },
        ]}
      >
        <Input />
      </FormItem>

      <FormItem
        label="ads.fields.url"
        name="url"
        rules={[
          {
            required: true,
            message: translate('validation.required'),
          },
          {
            type: 'url',
            message: translate('validation.invalidUrl'),
          },
        ]}
      >
        <Input />
      </FormItem>

      <DocumentUpload
        label="ads.fields.document"
        resourceId={id}
        maxWidth={1440}
        ratio={new Fraction(43, 12)}
        single
        documentInfo="ads.imageRestriction"
        required
      />

      <Typography.Title level={4}>
        {translate('ads.fields.location')}
      </Typography.Title>

      <RadiusInput />

      <div style={{ height: '500px' }}>
        <Map center={center}>
          <AdLocation />
        </Map>
      </div>
      <LocationInputs />
    </>
  )
}

function RadiusInput() {
  const longitude = Form.useWatch('longitude')
  const latitude = Form.useWatch('latitude')

  return (
    <FormItem
      label="ads.fields.radius"
      name="radius"
      required={isDefined.strict(longitude) || isDefined.strict(latitude)}
    >
      <InputNumber addonAfter="km" />
    </FormItem>
  )
}

function LocationInputs() {
  const radius = Form.useWatch('radius')

  const translate = useTranslate()

  return (
    <>
      <FormItem
        name="latitude"
        rules={[
          {
            required: !!radius,
            message: translate('validation.ads.location'),
          },
        ]}
        className="AteAdsLocationInput"
      >
        <Input hidden />
      </FormItem>
      <FormItem name="longitude" hidden>
        <Input />
      </FormItem>
    </>
  )
}

function AdLocation() {
  const { setFieldsValue } = Form.useFormInstance<Ad>()

  useMapEvent(
    'click',
    useCallback(
      (event: L.LeafletMouseEvent) => {
        const { latlng } = event
        const { lat, lng } = latlng

        setFieldsValue({
          latitude: lat,
          longitude: lng,
        })
      },
      [setFieldsValue],
    ),
  )

  return <LocationMarker />
}

function LocationMarker() {
  const { setFieldsValue } = Form.useFormInstance<Ad>()

  const longitude = Form.useWatch('longitude')
  const latitude = Form.useWatch('latitude')
  const radius = Form.useWatch('radius')

  const markerRef = useRef<L.Marker>(null)
  const dragend = useCallback(async () => {
    const marker = markerRef.current
    if (marker) {
      const { lat, lng } = marker.getLatLng()
      setFieldsValue({
        latitude: lat,
        longitude: lng,
      })
    }
  }, [setFieldsValue])

  if (isDefined.strict(longitude) && isDefined.strict(latitude)) {
    return (
      <>
        {radius && (
          <Circle
            center={[latitude, longitude]}
            radius={radius * 1000}
            color={colors.error}
            opacity={0.5}
            weight={2}
            stroke={false}
            fillColor={colors.primary}
          />
        )}
        <Marker
          ref={markerRef}
          position={[latitude, longitude]}
          icon={MarkerIcon}
          draggable
          eventHandlers={{
            dragend,
            click: () => {
              setFieldsValue({
                latitude: undefined,
                longitude: undefined,
              })
            },
          }}
        />
      </>
    )
  }

  return null
}
