import {
  Button,
  Input,
  Select,
  Typography,
  useSelect,
} from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { Link } from 'react-router-dom'
import { DocumentUpload } from 'src/components/DocumentUpload'
import { TextEditor } from 'src/components/TextEditor'
import { ResourcePathEnum } from 'src/types/api'
import { stripHtml } from 'string-strip-html'

import { FormItem } from '../../components/FormItem'

type Props = {
  id: string
  isCreate?: boolean
}

export function DispenserForm(props: Props) {
  const { id, isCreate } = props

  const translate = useTranslate()

  const { selectProps } = useSelect({
    resource: ResourcePathEnum.categories,
    optionLabel: 'name',
    optionValue: 'id',
  })

  const { selectProps: tagsSelectProps } = useSelect({
    resource: ResourcePathEnum.tags,
    optionLabel: 'name',
    optionValue: 'id',
  })

  return (
    <>
      <FormItem label="dispensers.fields.name" name="name" required>
        <Input maxLength={50} required />
      </FormItem>

      <FormItem label="dispensers.fields.code" name="code">
        <Input maxLength={50} />
      </FormItem>

      <DocumentUpload
        label="dispensers.fields.documents"
        resourceId={id}
        maxFiles={3}
        maxHeight={1440}
        maxWidth={1440}
        required
      />

      <FormItem
        label="dispensers.fields.description"
        name="description"
        required
        rules={[
          {
            validator(_, value) {
              const cleanValue = stripHtml(value).result
              if (cleanValue) return Promise.resolve()
              return Promise.reject(new Error(translate('validation.required')))
            },
          },
        ]}
      >
        <TextEditor />
      </FormItem>

      <FormItem label="dispensers.fields.positions">
        {isCreate ? (
          <Typography.Text>
            {translate('dispensers.actions.managePositionsUnavailable')}
          </Typography.Text>
        ) : (
          <Link to={`/dispensers/${id}/positions`} target={'_blank'}>
            <Button>{translate('dispensers.actions.managePositions')}</Button>
          </Link>
        )}
      </FormItem>

      <FormItem
        label="dispensers.fields.mainCategory"
        name="mainCategory"
        required
      >
        <Select {...selectProps} />
      </FormItem>

      <FormItem label="dispensers.fields.categories" name="categories">
        <Select {...selectProps} mode="multiple" />
      </FormItem>

      <FormItem name="tags" label="dispensers.fields.tags">
        <Select {...tagsSelectProps} mode="multiple" />
      </FormItem>

      <FormItem label="dispensers.fields.websiteUrl" name="websiteUrl">
        <Input type="url" maxLength={100} />
      </FormItem>
      <FormItem label="dispensers.fields.facebookUrl" name="facebookUrl">
        <Input type="url" maxLength={100} />
      </FormItem>
      <FormItem label="dispensers.fields.phoneNumber" name="phoneNumber">
        <Input type="tel" maxLength={50} />
      </FormItem>
    </>
  )
}
