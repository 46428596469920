import { Button, notification } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useMutation } from '@tanstack/react-query'
import firebase from 'firebase/compat/app'

import 'firebase/compat/auth'

type ResetPasswordButtonProps = {
  email: string
}
export function ResetPasswordButton(props: ResetPasswordButtonProps) {
  const { email } = props
  const translate = useTranslate()

  const { mutate, isLoading } = useMutation({
    mutationFn() {
      return firebase.auth().sendPasswordResetEmail(email)
    },
    onSuccess() {
      notification.success({
        message: translate('notifications.resetPassword'),
      })
    },
  })
  return (
    <Button onClick={() => mutate()} loading={isLoading}>
      {translate('users.buttons.resetPassword')}
    </Button>
  )
}
