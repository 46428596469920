/*
 * Beware, this code is AUTO-GENERATED
 * Any manual changes on this file would be eaten by the VØ|D
 */

type Integer = number

type Year = Integer
type Month = Integer
type Day = Integer
type Hour = Integer
type Minute = Integer
type Second = Integer
type HoursOffset = Integer
type SecondsOffset = Integer
export type DateTimeString =
  `${Year}-${Month}-${Day}T${Hour}:${Minute}:${Second}+${HoursOffset}:${SecondsOffset}`

export type IriReference = `/api/${ResourcePathEnum}/${string}`

export enum ResourceTypeEnum {
  'Ad' = 'Ad',
  'Category' = 'Category',
  'Client' = 'Client',
  'Client_get_client' = 'Client_get_client',
  'Client_get_clients' = 'Client_get_clients',
  'Client_get_user' = 'Client_get_user',
  'Client_get_users' = 'Client_get_users',
  'Config' = 'Config',
  'Dispenser' = 'Dispenser',
  'Dispenser_get_dispenser' = 'Dispenser_get_dispenser',
  'DispenserPosition' = 'DispenserPosition',
  'DispenserPosition_get_dispenser' = 'DispenserPosition_get_dispenser',
  'DispenserSchedule' = 'DispenserSchedule',
  'DispenserSchedule_get_dispenser' = 'DispenserSchedule_get_dispenser',
  'Document' = 'Document',
  'Document_get_dispenser' = 'Document_get_dispenser',
  'Item' = 'Item',
  'Item_get_dispenser' = 'Item_get_dispenser',
  'Log' = 'Log',
  'Promotion' = 'Promotion',
  'Promotion_get_dispenser' = 'Promotion_get_dispenser',
  'Rating' = 'Rating',
  'Rating_read' = 'Rating_read',
  'Rating_report_read' = 'Rating_report_read',
  'Report' = 'Report',
  'Report_read' = 'Report_read',
  'Report_report_read' = 'Report_report_read',
  'Tag' = 'Tag',
  'Tag_tag_type_read' = 'Tag_tag_type_read',
  'TagType_tag_type_read' = 'TagType_tag_type_read',
  'User' = 'User',
  'User_get_client' = 'User_get_client',
  'User_get_clients' = 'User_get_clients',
  'User_get_user' = 'User_get_user',
  'User_get_users' = 'User_get_users',
  'User_report_read' = 'User_report_read',
  'ViewClusters_clusters_read' = 'ViewClusters_clusters_read',
}
export enum ResourcePathEnum {
  'ads' = 'ads',
  'categories' = 'categories',
  'clients' = 'clients',
  'clusters' = 'clusters',
  'configs' = 'configs',
  'dispenser_positions' = 'dispenser_positions',
  'dispenser_schedules' = 'dispenser_schedules',
  'dispensers' = 'dispensers',
  'documents' = 'documents',
  'items' = 'items',
  'logs' = 'logs',
  'promotions' = 'promotions',
  'ratings' = 'ratings',
  'reports' = 'reports',
  'tag_types' = 'tag_types',
  'tags' = 'tags',
  'users' = 'users',
  'view_clusters' = 'view_clusters',
  'minio' = 'minio',
  'authentication' = 'authentication',
}

export interface Ad {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.Ad
  readonly id: IriReference
  label: string
  url: string
  document?: IriReference // iri-reference
  latitude?: number
  longitude?: number
  radius?: number
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
}

export interface Category {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.Category
  readonly id: IriReference
  name: string
  documentActive?: IriReference // iri-reference
  documentInactive?: IriReference // iri-reference
  hasMultiplePositions: boolean
  position: Integer
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
}

export interface Client {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.Client
  readonly id: IriReference
  dispenserLimit: Integer
  dispensers: IriReference[]
  users: IriReference[]
  description?: string
  isActive: boolean
  code?: string
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
}

export interface Config {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.Config
  readonly id: IriReference
  code: string
  value: string
  version: Integer
  description?: string
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
}

export interface Dispenser {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.Dispenser
  readonly id: IriReference
  name: string
  description: string
  disabledAt?: DateTimeString // date-time
  isDisabled: boolean
  recommandations: IriReference[]
  tags: IriReference[]
  owner: IriReference // iri-reference
  positions: IriReference[]
  mainCategory?: IriReference // iri-reference
  categories: IriReference[]
  items: IriReference[]
  /** CodegenError: Cannot generate property type : {} */
  distance: any
  documents: IriReference[]
  logs: IriReference[]
  readonly last_notification?: DateTimeString // date-time
  code?: string
  websiteUrl?: string
  facebookUrl?: string
  phoneNumber?: string
  ratings: IriReference[]
  users: IriReference[]
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
  /** CodegenError: Cannot generate property type : {"readOnly":true} */
  readonly countRecommandations: any
  readonly hasActivePromotion: boolean
  readonly activePosition?: IriReference // iri-reference
  readonly hasActiveSchedule: boolean
  /** CodegenError: Cannot generate property type : {"readOnly":true} */
  readonly lastUpdatedAt: any
  /** CodegenError: Cannot generate property type : {"readOnly":true} */
  readonly isDisabledByClient: any
  readonly isFoodtruck: boolean
  lastNotification?: DateTimeString // date-time
  readonly canBeNotify: boolean
  readonly nbRatings: Integer
  readonly averageRating: number
}

export interface DispenserPosition {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.DispenserPosition
  readonly id: IriReference
  latitude: number
  longitude: number
  dispenser: IriReference // iri-reference
  schedules: IriReference[]
  postalCode?: string
  fullAddress?: string
  city?: string
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
}

export interface DispenserSchedule {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.DispenserSchedule
  readonly id: IriReference
  weekday: DispenserScheduleWeekdayEnum
  start: Integer
  end: Integer
  position: IriReference // iri-reference
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
}

export enum DispenserScheduleWeekdayEnum {
  'monday' = 'monday',
  'tuesday' = 'tuesday',
  'wednesday' = 'wednesday',
  'thursday' = 'thursday',
  'friday' = 'friday',
  'saturday' = 'saturday',
  'sunday' = 'sunday',
}

export interface Document {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.Document
  readonly id: IriReference
  name?: string
  size: Integer
  mimeType?: string
  extension?: string
  bucketId: string
  dispenser?: IriReference // iri-reference
  item?: IriReference // iri-reference
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
}

export interface Item {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.Item
  readonly id: IriReference
  name: string
  price: Integer
  description?: string
  promotions: IriReference[]
  dispenser: IriReference // iri-reference
  documents: IriReference[]
  logs: IriReference[]
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
  readonly activePromotion: boolean
  readonly activePromotionObject?: IriReference // iri-reference
}

export interface Log {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.Log
  readonly id: IriReference
  user: IriReference // iri-reference
  json: string[]
  resourceUuid: string
  resourceClass: string
  method: string
  dispenser?: IriReference // iri-reference
  item?: IriReference // iri-reference
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
}

export interface Promotion {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.Promotion
  readonly id: IriReference
  price: Integer
  startDate: DateTimeString // date-time
  endDate: DateTimeString // date-time
  item: IriReference // iri-reference
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  readonly active: boolean
}

export interface Rating {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.Rating
  readonly id: IriReference
  note?: Integer
  comment: string
  dispenser?: IriReference // iri-reference
  user: IriReference // iri-reference
  /** CodegenError: Cannot generate property type : {"nullable":true,"anyOf":[{"$ref":"#/components/schemas/Rating.jsonld"}]} */
  response?: any
  reports: Report[]
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  readonly pseudo: string
}

export interface Report {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.Report
  readonly id: IriReference
  reason: string
  user: User
  rating: Rating
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  readonly dispenser: string
}

export interface Tag {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.Tag
  readonly id: IriReference
  name: string
  dispensers: IriReference[]
  document?: IriReference // iri-reference
  description: string
  position: Integer
  tagType?: IriReference // iri-reference
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
}

export interface User {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.User
  readonly id: IriReference
  firebaseId?: string
  readonly isAdmin: boolean
  readonly isModerator: boolean
  readonly isTemporary: boolean
  roles: string[]
  recommendations: IriReference[]
  client?: IriReference // iri-reference
  email?: string
  token?: string
  isGhost: boolean
  cguAgreed?: boolean
  anonymizeAt?: DateTimeString // date-time
  lastLoginAt?: DateTimeString // date-time
  ratings: IriReference[]
  reports: IriReference[]
  favorites: IriReference[]
  pseudo?: string
  isMobileUser: boolean
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  /** CodegenError: Cannot generate property type : {"readOnly":true} */
  readonly password: any
  /** CodegenError: Cannot generate property type : {"readOnly":true} */
  readonly salt: any
  readonly username: string
}
