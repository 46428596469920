import { RefreshButton, Space, useForm, Edit, Form } from '@pankod/refine-antd'
import { type ResourceRouterParams } from '@pankod/refine-core'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { cleanHydraId } from 'src/adapters/DataProvider'
import { useEnv } from 'src/adapters/Env'
import { hydrateDocuments } from 'src/components/DocumentUpload'
import type { Item } from 'src/types/api'

import { SuggestNotify } from '../Dispenser/Notify/SuggestNotify'

import { ItemForm } from './ItemForm'
import { PromotionList } from './PromotionList'

export function EditView() {
  const navigate = useNavigate()
  const env = useEnv()
  const { id } = useParams<ResourceRouterParams>()

  const queryClient = useQueryClient()

  if (!id) {
    throw new Error('pages.error.404')
  }

  const { formProps, saveButtonProps, form, queryResult } = useForm<Item>({
    redirect: false,
    onMutationSuccess(data) {
      const dispenser = cleanHydraId(data.data.dispenser)
      navigate(`/dispensers/show/${dispenser}`, { replace: true })
    },
  })

  useEffect(() => {
    if (queryResult?.isFetched) {
      const item = queryResult.data?.data
      form.setFieldsValue({
        ...item,
        documents: hydrateDocuments(item?.documents ?? [], env.API_URL),
      })
    }
  }, [queryResult?.isFetched, queryResult?.data, form, env.API_URL])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <SuggestNotify dispenserId={queryResult?.data?.data.dispenser} />

      <Edit
        saveButtonProps={{
          ...saveButtonProps,
        }}
        pageHeaderProps={{
          extra: <RefreshButton />,
        }}
        deleteButtonProps={{
          loading: queryResult?.isLoading,
          onSuccess() {
            void queryClient.invalidateQueries(['default', 'items'])
            if (queryResult?.data?.data) {
              const dispenser = cleanHydraId(queryResult?.data?.data.dispenser)
              navigate(`/dispensers/show/${dispenser}`, { replace: true })
            }
          },
        }}
      >
        <Form {...formProps} layout="vertical">
          <ItemForm id={id} />
        </Form>
      </Edit>
      {queryResult?.data && <PromotionList product={queryResult.data.data} />}
    </Space>
  )
}
