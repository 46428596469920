import firebase from 'firebase/compat/app'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Env } from 'src/adapters/Env'

import { App } from './App'
import { setup } from './setup'
import './i18n'

const env = Env()
setup(env)

const firebaseConfig = {
  apiKey: env.FIREBASE_API_KEY,
  authDomain: env.FIREBASE_AUTH_DOMAIN,
  projectId: env.FIREBASE_PROJECT_ID,
  storageBucket: env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
  appId: env.FIREBASE_APP_ID,
  measurementId: env.FIREBASE_MEASUREMENT_ID,
}
firebase.initializeApp(firebaseConfig)

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <App env={env} />
  </React.StrictMode>,
)
