import type { ResourceRouterParams } from '@pankod/refine-core'
import { useResourceWithRoute, useRouterContext } from '@pankod/refine-core'
import type { ResourcePathEnum } from 'src/types/api'

export type RecordProps = {
  resourceName?: ResourcePathEnum | string
  recordItemId?: string
}

// inspired by refine's source code
export function useResourceFromProps(props?: RecordProps) {
  const { resourceName: propResourceName, recordItemId } = props ?? {}
  const resourceWithRoute = useResourceWithRoute()
  const { useParams } = useRouterContext()
  const { resource: routeResourceName, id: idFromRoute } =
    useParams<ResourceRouterParams>()
  const resourceName = propResourceName ?? routeResourceName
  const resource = resourceWithRoute(resourceName)

  return {
    resource,
    recordItemId: recordItemId ?? idFromRoute,
  }
}
