import { colors } from '@alltime-eat/design-tokens'
import {
  DeleteButton,
  Show,
  Space,
  Table,
  Tag,
  useTable,
  Button,
  Icons,
  Modal,
  Input,
  useModalForm,
  Form,
  Typography,
} from '@pankod/refine-antd'
import type { ResourceRouterParams } from '@pankod/refine-core'
import {
  Authenticated,
  LayoutWrapper,
  useOne,
  useTranslate,
  usePermissions,
  useGetIdentity,
} from '@pankod/refine-core'
import { useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useParams, type RouteProps } from 'react-router'
import { cleanHydraId, toHydraId } from 'src/adapters/DataProvider'
import { useEnv } from 'src/adapters/Env'
import type { UserIdentity } from 'src/Auth'
import { DateField } from 'src/components/DateField'
import { FormItem } from 'src/components/FormItem'
import { Rate } from 'src/components/Rate'
import type { IriReference, Rating } from 'src/types/api'
import { ResourcePathEnum } from 'src/types/api'
import type {
  ExtendedDispenser,
  ExtendedUser,
} from 'src/types/api/extendedTypes'
import { RoleEnum } from 'src/types/api/extendedTypes'

import { TypographyLink } from '../../../components/TypographyLink'

import { RatingCard } from './RatingCard'

export const DispenserRatingsRoute: RouteProps = {
  path: '/dispensers/:id/avis',
  element: (
    <Authenticated>
      <LayoutWrapper>
        <DispenserRatings />
      </LayoutWrapper>
    </Authenticated>
  ),
}

function DispenserRatings() {
  const { id } = useParams<ResourceRouterParams>()
  if (!id) {
    throw new Error('pages.error.dispenserMissing')
  }
  const navigate = useNavigate()
  const { data: permissions } = usePermissions<RoleEnum[]>()

  const { data: identity } = useGetIdentity<UserIdentity>()

  const { tableProps } = useTable<Rating>({
    resource: ResourcePathEnum.ratings,
    initialSorter: [{ field: 'updatedAt', order: 'desc' }],
    permanentFilter: [
      {
        field: 'dispenser.id',
        operator: 'eq',
        value: id,
      },
    ],
  })

  const { data, isLoading } = useOne<ExtendedDispenser>({
    resource: ResourcePathEnum.dispensers,
    id,
  })
  const dispenser = data?.data

  const translate = useTranslate()

  const editedResponse = useRef<Record<IriReference, string | undefined>>({})

  function onCommentSuccess(iri: IriReference, comment: string) {
    editedResponse.current[iri] = comment
  }
  const [selectedRating, setSelectedRating] = useState<Rating>()
  function onSelectRating(rating: Rating) {
    const comment = editedResponse.current[rating['@id']]
    setSelectedRating({
      ...rating,
      response: {
        ...rating.response,
        comment: comment ?? rating.response?.comment,
      },
    })
  }

  if (!identity?.me || !dispenser) return null

  const isOwner = identity.me.client?.dispensers.includes(
    toHydraId(ResourcePathEnum.dispensers, id),
  )

  return (
    <Show
      isLoading={isLoading}
      canDelete={false}
      canEdit={false}
      resource="dispensers"
      pageHeaderProps={{
        subTitle: (
          <Tag>
            {translate(
              dispenser?.isDisabledByClient
                ? 'dispensers.values.isDisabled.byClient'
                : dispenser?.isDisabled
                  ? 'dispensers.values.isDisabled.true'
                  : 'dispensers.values.isDisabled.false',
            )}
          </Tag>
        ),
        extra: <></>,
        onBack: () => navigate(-1),
      }}
    >
      {selectedRating && (
        <ResponseModal
          rating={selectedRating}
          user={identity.me}
          onClose={() => setSelectedRating(undefined)}
          onSuccess={onCommentSuccess}
        />
      )}
      {dispenser && (
        <RatingCard
          count={dispenser?.nbRatings}
          value={dispenser?.averageRating}
        />
      )}
      <Table {...tableProps} rowKey="id">
        <Table.Column<Rating>
          dataIndex="user"
          title={translate('ratings.fields.user')}
          render={(_, rating) => {
            return (
              <TypographyLink
                to={`/${ResourcePathEnum.users}/show/${cleanHydraId(
                  rating.user,
                )}`}
              >
                {rating.pseudo}
              </TypographyLink>
            )
          }}
        />
        <Table.Column
          dataIndex="note"
          title={translate('ratings.fields.note')}
          render={(value) => <Rate value={value} />}
        />

        <Table.Column
          dataIndex="updatedAt"
          title={translate('ratings.fields.updatedAt')}
          render={(value) => <DateField value={value} />}
        />

        <Table.Column
          dataIndex="comment"
          title={translate('ratings.fields.comment')}
        />

        <Table.Column<Rating>
          dataIndex="actions"
          render={(_, rating) => {
            const hasResponse = Boolean(rating.response)
            return (
              <Space>
                {isOwner && (
                  <Button
                    icon={
                      hasResponse ? (
                        <Icons.EditOutlined />
                      ) : (
                        <Icons.MessageOutlined />
                      )
                    }
                    style={{
                      background: hasResponse ? colors.black20 : undefined,
                    }}
                    onClick={() => onSelectRating(rating)}
                  >
                    {translate(
                      `ratings.buttons.response.${
                        hasResponse ? 'edit' : 'add'
                      }`,
                    )}
                  </Button>
                )}
                {permissions?.includes(RoleEnum.admin) && (
                  <DeleteButton
                    resourceName={ResourcePathEnum.ratings}
                    recordItemId={rating.id}
                  />
                )}
              </Space>
            )
          }}
        />
      </Table>
    </Show>
  )
}

type ResponseModalProps = {
  rating: Rating
  user: ExtendedUser
  onClose: () => void
  onSuccess: (id: IriReference, comment: string) => void
}
function ResponseModal(props: ResponseModalProps) {
  const { rating, user, onClose, onSuccess } = props
  const translate = useTranslate()
  const env = useEnv()

  const { formProps, formLoading } = useModalForm<Rating>({
    action: 'create',
    resource: 'ratings/response',
    defaultFormValues: {
      rating: rating['@id'],
      user: user['@id'],
      comment: rating.response.comment,
    },
    onMutationSuccess(response) {
      const { data } = response
      onSuccess(rating['@id'], data.response.comment)
      onClose()
    },
  })
  const hasResponse = Boolean(rating.response?.comment)

  return (
    <>
      <Modal visible={true} onCancel={onClose} centered footer={null}>
        <Form {...formProps} layout="vertical">
          <Typography.Title
            level={5}
            style={{
              color: colors.black80,
              fontWeight: 700,
            }}
          >
            {translate('ratings.titles.ownerResponse')}
          </Typography.Title>
          <DateField value={new Date()} />
          <FormItem name="rating" hidden />
          <FormItem name="user" hidden />
          <FormItem name="comment">
            <Input.TextArea rows={4} maxLength={500} showCount required />
          </FormItem>

          <Typography.Paragraph style={{ color: colors.black60 }}>
            <Trans
              i18nKey="ratings.response.terms"
              components={{
                cgu: (
                  <a
                    href={env.CGU_URL}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textDecoration: 'underline',
                    }}
                  />
                ),
                terms: (
                  <a
                    href={env.TERMS_URL}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textDecoration: 'underline',
                    }}
                  />
                ),
              }}
            />
          </Typography.Paragraph>

          <Space style={{ width: '100%', justifyContent: 'center' }}>
            <Button type="primary" htmlType="submit" loading={formLoading}>
              {translate(
                `ratings.buttons.response.${hasResponse ? 'edit' : 'add'}`,
              )}
            </Button>
          </Space>
        </Form>
      </Modal>
    </>
  )
}
