import { Tag, Checkbox, useForm, Form, Edit } from '@pankod/refine-antd'
import type { ResourceRouterParams } from '@pankod/refine-core'
import { useTranslate } from '@pankod/refine-core'
import { useNavigate, useParams } from 'react-router'
import { DefaultEditActions } from 'src/components/DefaultEditActions'
import { ToggleEnabledButton } from 'src/components/ToggleEnabledButton'
import type { DispenserEdit } from 'src/types/api/extendedTypes'

import { DispenserForm } from './DispenserForm'
import { SuggestNotify } from './Notify/SuggestNotify'

export function EditView() {
  const navigate = useNavigate()
  const { id } = useParams<ResourceRouterParams>()
  if (!id) {
    throw new Error('pages.error.dispenserMissing')
  }
  const translate = useTranslate()

  const { formProps, saveButtonProps, queryResult } = useForm<DispenserEdit>({
    resource: 'dispenserEdit',
    redirect: false,
    id,
    onMutationSuccess() {
      navigate(`/dispensers/show/${id}`, { replace: true })
    },
  })
  const dispenser = queryResult?.data?.data
  const isDisabled = dispenser?.isDisabled

  return (
    <>
      <SuggestNotify dispenserId={queryResult?.data?.data['@id']} />
      <br />
      <Edit
        saveButtonProps={saveButtonProps}
        headerProps={{
          subTitle: (
            <Tag>
              {translate(
                dispenser?.isDisabledByClient
                  ? 'dispensers.values.isDisabled.byClient'
                  : dispenser?.isDisabled
                    ? 'dispensers.values.isDisabled.true'
                    : 'dispensers.values.isDisabled.false',
              )}
            </Tag>
          ),
        }}
        headerButtons={
          <DefaultEditActions
            saveButtonProps={saveButtonProps}
            canDelete={isDisabled ?? false}
          >
            {dispenser && (
              <ToggleEnabledButton<DispenserEdit>
                resource="dispenserEdit"
                record={dispenser}
                disableProp="isDisabled"
                isDisabled={isDisabled!}
              />
            )}
          </DefaultEditActions>
        }
      >
        <Form {...formProps} layout="vertical">
          <DispenserForm id={id} />
          <Form.Item
            name="warning"
            valuePropName="checked"
            required
            rules={[
              {
                validator(_, value) {
                  if (value) return Promise.resolve()
                  return Promise.reject(
                    new Error(translate('validation.acceptAgreement')),
                  )
                },
              },
            ]}
          >
            <Checkbox>{translate('dispensers.fields.agreement')}</Checkbox>
          </Form.Item>
        </Form>
      </Edit>
    </>
  )
}
