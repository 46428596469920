import { Typography } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import type { ReactNode } from 'react'

import { EmptyText } from './EmptyText'

const { Title, Paragraph } = Typography

type Props<T> = {
  label: string
  name: keyof T
  record?: T
  link?: boolean
}

export function TextField<T>(props: Props<T>) {
  const { label, name, record, link } = props

  const translate = useTranslate()

  function renderValue() {
    if (record && record[name]) {
      if (link) {
        return (
          <a
            href={record[name] as unknown as string}
            target="_blank"
            rel="noreferrer"
          >
            {record[name] as ReactNode}
          </a>
        )
      }
      return <>{record[name]}</>
    }
    return <EmptyText />
  }

  return (
    <>
      <Title level={5}>{translate(label)}</Title>
      <Paragraph>{renderValue()}</Paragraph>
    </>
  )
}
