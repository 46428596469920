import { Alert, Button, notification, Space } from '@pankod/refine-antd'
import { useOne, useTranslate } from '@pankod/refine-core'
import type { AxiosError } from 'axios'
import { useContext, useState } from 'react'
import type { HydraId } from 'src/adapters/DataProvider'
import { cleanHydraId, toHydraId } from 'src/adapters/DataProvider'
import { useHttpClient } from 'src/adapters/HTTPClient'
import type { Dispenser, IriReference } from 'src/types/api'
import { ResourcePathEnum } from 'src/types/api'

import type { UpdatedDispenser } from './useWatchDispenserUpdates'
import { UpdatedDispensersContext } from './useWatchDispenserUpdates'

type Props = {
  dispenserId?: string | IriReference
}

export function SuggestNotify(props: Props) {
  const { dispenserId } = props
  const updatedDispensers = useContext(UpdatedDispensersContext)
  const itemHydraId = toHydraId(ResourcePathEnum.dispensers, dispenserId || '')
  const updateInfo = updatedDispensers.get(itemHydraId)
  const enabled = Boolean(dispenserId && updateInfo)

  const translate = useTranslate()
  const { data: dispenser, refetch } = useOne<Dispenser>({
    resource: ResourcePathEnum.dispensers,
    id: itemHydraId,
    queryOptions: { enabled, keepPreviousData: false },
  })

  const [notify, isPending] = useNotify(itemHydraId, updateInfo, refetch)

  if (!dispenser || !enabled) return null

  if (!dispenser.data.canBeNotify) {
    return (
      <Alert
        type="info"
        showIcon
        message={translate('suggestNotify.unavailable')}
        closable
        onClose={() => updatedDispensers.remove(itemHydraId)}
      />
    )
  }

  return (
    <Alert
      type="info"
      showIcon
      message={translate(
        updateInfo?.type === 'edition'
          ? 'suggestNotify.edition.message'
          : 'suggestNotify.promotion.message',
      )}
      description={
        <Space direction="vertical" style={{ width: '100%' }}>
          {translate('suggestNotify.info')}
          <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              size="small"
              danger
              type="ghost"
              onClick={() => {
                updatedDispensers.remove(itemHydraId)
              }}
            >
              {translate('suggestNotify.actions.dont')}
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={notify}
              loading={isPending}
            >
              {translate('suggestNotify.actions.notify')}
            </Button>
          </Space>
        </Space>
      }
    />
  )
}

function useNotify(
  itemId: HydraId,
  meta: UpdatedDispenser | undefined,
  refetch: () => void,
) {
  // would be better to use useMutation from react-query, but the context seem broken
  const translate = useTranslate()
  const httpClient = useHttpClient()
  const [pending, setPending] = useState(false)

  const updatedDispensers = useContext(UpdatedDispensersContext)

  const notifyUrl =
    meta?.type === 'promotion'
      ? `/${ResourcePathEnum.promotions}/${cleanHydraId(
          meta.promotionId,
        )}/notify`
      : `/${ResourcePathEnum.dispensers}/${cleanHydraId(itemId)}/notify`

  async function notify() {
    setPending(true)
    try {
      await httpClient.get(notifyUrl)
      refetch()
      notification.success({
        message: translate('suggestNotify.notification.success'),
      })
      updatedDispensers.remove(itemId)
    } catch (e) {
      notification.error({
        message: translate(`suggestNotify.notification.failure`),
        description: (e as AxiosError).response?.status,
      })
    } finally {
      setPending(false)
    }
  }

  return [notify, pending] as const
}
