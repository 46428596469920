import type { DataProvider } from '@pankod/refine-core'
import type { Document } from 'src/types/api'
import { ResourcePathEnum } from 'src/types/api'
import type { UploadFile } from 'src/types/api/extendedTypes'

import { toHydraId } from './DataProvider'

type ReturnType = {
  create: DataProvider['create']
  update: DataProvider['update']
}

type Config<T> = {
  dataProvider: DataProvider
  prop: keyof T
  single?: boolean
}

export function DocumentHandlerProxy<T>(config: Config<T>): ReturnType {
  const { dataProvider, prop, single } = config
  function createDocuments(documents?: Partial<Document>[]) {
    const documentsQueries = documents?.map((document) =>
      dataProvider.create({
        resource: ResourcePathEnum.documents,
        variables: document,
      }),
    )

    return Promise.allSettled(documentsQueries ?? [])
  }

  function deleteDocuments(documents?: UploadFile[]) {
    if (documents && documents.length > 0) {
      void dataProvider.deleteMany!({
        resource: ResourcePathEnum.documents,
        ids: documents?.map((document) => document.uid),
      })
    }
  }

  async function manageDocuments(documents?: UploadFile[]) {
    if (!documents) return undefined

    const succeedDocuments = documents.filter(
      (document) => document.status !== 'error',
    )
    const documentsToCreate = succeedDocuments.filter(
      (document) => !document.exists,
    )
    const documentsToDelete = succeedDocuments.filter(
      (document) => document.toDelete,
    )
    deleteDocuments(documentsToDelete)

    const documentsUntouched = succeedDocuments
      .filter((document) => document.exists && !document.toDelete)
      .map((document) => toHydraId(ResourcePathEnum.documents, document.uid))

    const res = await createDocuments(documentsToCreate)

    const createdDocumentsIds = res
      .map((promiseResult) => {
        if (promiseResult.status === 'fulfilled') {
          return promiseResult.value.data.id
        }
        return undefined
      })
      .filter(Boolean)

    return [...documentsUntouched, ...createdDocumentsIds]
  }

  return {
    async create(params) {
      const { variables } = params
      const documents = (variables as any)[prop] as UploadFile[]
      const res = await manageDocuments(documents)

      return dataProvider.create({
        ...params,
        variables: {
          ...variables,
          [prop]: single ? res?.[0] : res,
        },
      })
    },

    async update(params) {
      const { variables } = params
      const documents = (variables as any)[prop] as UploadFile[]

      const res = await manageDocuments(documents)

      return dataProvider.update({
        ...params,
        variables: {
          ...variables,
          [prop]: single ? (res?.[0] ?? null) : res,
        },
      })
    },
  }
}
