import { Navigate } from 'react-router-dom'

export * from './AuthContext'
export * from './AuthProvider'

export const authRoutes = [
  {
    path: '/login',
    element: <Navigate to="/" replace />,
  },
  {
    path: '/signup',
    element: <Navigate to="/" replace />,
  },
]
