import { InputNumber, NumberField } from '@pankod/refine-antd'
import type { NumberFieldProps } from '@pankod/refine-antd'

type Props = {
  value: number
} & NumberFieldProps

export function CurrencyField(props: Props) {
  const { value, ...rest } = props

  const formatedValue = value / 100

  return (
    <NumberField
      value={formatedValue}
      options={{ style: 'currency', currency: 'EUR' }}
      {...rest}
    />
  )
}

type CurrencyInputProps = {
  value?: number
  onChange?: (value: number | null) => void
  required?: boolean
}

export function CurrencyInput(props: CurrencyInputProps) {
  const { value, onChange, required } = props

  const formatedValue = value && value / 100
  function handleChange(newValue: number | null) {
    return onChange?.(newValue && Math.round(newValue * 100))
  }

  return (
    <InputNumber
      value={formatedValue}
      onChange={handleChange}
      controls={false}
      decimalSeparator=","
      addonAfter="€"
      required={required}
      style={{ width: '100%' }}
    />
  )
}
