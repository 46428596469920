import { Table, useTable, Typography } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { DateField } from 'src/components/DateField'
import { ReferenceLinkField } from 'src/components/ReferenceField'
import type { Dispenser, Item, Log, User } from 'src/types/api'
import { ResourcePathEnum } from 'src/types/api'
import type { ExtendedLog } from 'src/types/api/extendedTypes'

const { Text } = Typography

export function LatestLogs() {
  const translate = useTranslate()

  const { tableProps } = useTable<ExtendedLog>({
    resource: ResourcePathEnum.logs,
    initialSorter: [{ field: 'createdAt', order: 'desc' }],
    initialPageSize: 20,
  })

  return (
    <Table
      {...tableProps}
      rowKey="id"
      expandable={{
        rowExpandable(log) {
          return log.json.length > 0
        },
        expandedRowRender(log) {
          const logData = log.json
            .map((logObject) =>
              Object.entries(logObject).map(([key, value]) => {
                return { property: key, ...value }
              }),
            )
            .flat()
          return (
            <Table
              size="small"
              pagination={false}
              dataSource={logData}
              columns={[
                {
                  title: translate('logs.fields.property'),
                  dataIndex: 'property',
                  key: 'property',
                  render(value) {
                    return translate(`dispensers.fields.${value}`)
                  },
                },
                {
                  title: translate('logs.fields.old'),
                  dataIndex: 'old',
                  key: 'old',
                },
                {
                  title: translate('logs.fields.new'),
                  dataIndex: 'new',
                  key: 'new',
                },
              ]}
            />
          )
        },
      }}
    >
      <Table.Column
        dataIndex="createdAt"
        title={translate('logs.fields.createdAt')}
        render={(value) => <DateField format="LLL" value={value} />}
      />
      <Table.Column<Log>
        dataIndex="user"
        title={translate('logs.fields.user')}
        render={(_, log) => {
          const { user } = log

          if (!user) {
            return (
              <Text type="secondary" italic>
                {translate('users.deleted')}
              </Text>
            )
          }

          return (
            <ReferenceLinkField<User>
              resourceName={ResourcePathEnum.users}
              recordItemId={user}
              render={(element) => element.email}
            />
          )
        }}
      />
      <Table.Column<Log>
        dataIndex="resource"
        title={translate('logs.fields.resource')}
        render={(_, log) => {
          const { resourceClass, resourceUuid } = log
          const resourceName = getResourcePath(resourceClass)
          return (
            <ReferenceLinkField<Dispenser | Item>
              resourceName={resourceName}
              recordItemId={resourceUuid}
              render={(element) => {
                return element?.name
              }}
              link={resourceName === ResourcePathEnum.items ? 'edit' : 'show'}
            />
          )
        }}
      />
    </Table>
  )
}

export function getResourcePath(resourceClass: string) {
  const resourceName = resourceClass.split('\\').pop()
  if (resourceName === 'Item') return ResourcePathEnum.items
  if (resourceName === 'Dispenser') return ResourcePathEnum.dispensers
  if (resourceName === 'DispenserPosition') return ResourcePathEnum.dispensers
  if (resourceName === 'DispenserSchedule') return ResourcePathEnum.dispensers
  throw Error(
    `Unexpected value for resourceClass : ${JSON.stringify(resourceClass)}`,
  )
}
