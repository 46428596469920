import { DatePicker as RefineDatePicker } from '@pankod/refine-antd'
import frFr from 'antd/es/locale/fr_FR'
import type {
  RangePickerDateProps,
  PickerDateProps,
} from 'antd/lib/date-picker/generatePicker'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'

export function DatePicker(props: PickerDateProps<Dayjs>) {
  const { value } = props
  return (
    <RefineDatePicker
      format="L"
      locale={frFr.DatePicker}
      style={{ width: '100%' }}
      {...props}
      value={value && dayjs(value)}
    />
  )
}

const { RangePicker: RefineRangePicker } = RefineDatePicker

export function RangePicker(props: RangePickerDateProps<Dayjs>) {
  return <RefineRangePicker format="L" locale={frFr.DatePicker} {...props} />
}
