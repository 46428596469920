type Dimension = {
  height: number
  width: number
}

export function getImageDimension(file: File) {
  return new Promise<Dimension>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = (e) => {
      if (e.target?.result) {
        const image = new Image()
        image.src = e.target?.result as string

        image.onload = () => {
          return resolve({ height: image.height, width: image.width })
        }
      } else reject(new Error('File unreadable'))
    }
  })
}
