import type { CrudSorting } from '@pankod/refine-core'
import type { AxiosRequestConfig } from 'axios'
import { isEmpty } from 'remeda'
import { cleanHydraId } from 'src/adapters/DataProvider/hydraId'

import { buildFilters } from './buildFilters'
import type {
  CreateParams,
  GetOneParams,
  GetListParams,
  UpdateParams,
  DeleteOneParams,
  HydraParams,
  CustomParams,
} from './types'

function toItemUrl(resource: string, id: string | number) {
  // Check if this is a jsonLd id
  if (typeof id === 'string' && id.includes('/')) {
    // Keep only the last 2 parts of the id (ex: /api/users/uuid becomes users/uuid)
    return `/${id.split('/').slice(-2).join('/')}`
  }
  return `/${resource}/${id}`
}

function toResourceUrl(resource: string) {
  return `/${resource}`
}

const baseHeaders = { Accept: 'application/ld+json' }

export const requestBuilder = {
  getOne(options: GetOneParams): AxiosRequestConfig {
    const { id, resource, metaData } = options

    return {
      headers: { ...baseHeaders, ...metaData?.headers },
      method: 'GET',
      url: toItemUrl(resource, id),
    }
  },

  getList(options: GetListParams): AxiosRequestConfig {
    const { resource, pagination, sort, filters, metaData } = options

    const params: HydraParams = {
      page: pagination?.current ?? 1,
      itemsPerPage: pagination?.pageSize ?? 25,
      ...buildSort(sort ?? []),
      ...buildFilters(filters ?? []),
    }

    return {
      headers: { ...baseHeaders, ...metaData?.headers },
      method: 'GET',
      url: toResourceUrl(resource),
      params,
    }
  },

  create(options: CreateParams): AxiosRequestConfig {
    const { resource, variables, metaData } = options

    const recordId = (variables as any).id
    const extraHeaders = recordId
      ? { 'X-CREATED-ID': cleanHydraId(recordId) }
      : {}

    return {
      headers: { ...baseHeaders, ...extraHeaders, ...metaData?.headers },
      method: 'POST',
      url: toResourceUrl(resource),
      data: { ...(variables as any), id: undefined },
    }
  },

  update(options: UpdateParams): AxiosRequestConfig {
    const { resource, id, variables, metaData } = options

    return {
      headers: { ...baseHeaders, ...metaData?.headers },
      method: 'PUT',
      url: toItemUrl(resource, id),
      data: { ...(variables as any), id: undefined },
    }
  },

  delete(options: DeleteOneParams): AxiosRequestConfig {
    const { id, resource, metaData } = options

    return {
      headers: { ...baseHeaders, ...metaData?.headers },
      method: 'DELETE',
      url: id ? toItemUrl(resource, id) : toResourceUrl(resource),
    }
  },

  custom(options: CustomParams): AxiosRequestConfig {
    const { url, method, filters, headers, payload, query, sort, metaData } =
      options

    if (isEmpty({ ...metaData, queryContext: undefined })) {
      throw Error(`metaData in custom request are not implemented`)
    }

    return {
      url,
      method,
      headers: { ...baseHeaders, ...headers },
      params: {
        ...(query ?? {}),
        ...buildSort(sort ?? []),
        ...buildFilters(filters ?? []),
      },
      data: payload,
    }
  },
}

function buildSort(sortParams: CrudSorting) {
  const result: Record<string, string> = {}
  sortParams.forEach((sort) => {
    result[`order[${sort?.field}]`] = sort?.order ?? 'asc'
  })

  return result
}
