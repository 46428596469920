import type { UploadFile as RefineUploadFile } from '@pankod/refine-antd'

import type {
  Client,
  Dispenser,
  DispenserPosition,
  DispenserSchedule,
  Document,
  Item,
  Log,
  Promotion,
  User,
} from '.'

export type ExtendedClient = Omit<Client, 'users'> & {
  users: User[]
}

export type ExtendedDispenser = Omit<
  Dispenser,
  'items' | 'documents' | 'positions'
> & {
  items: ExtendedItem[]
  documents: Document[]
  positions: ExtendedDispenserPosition[]
}

export type DispenserEdit = Omit<Dispenser, 'documents'> & {
  documents: UploadFile[]
}

export type ExtendedItem = Omit<
  Item,
  'documents' | 'promotions' | 'activePromotionObject'
> & {
  documents: Document[]
  promotions: Promotion[]
  activePromotionObject?: Promotion
}

export type ExtendedDispenserPosition = Omit<DispenserPosition, 'schedules'> & {
  schedules: DispenserSchedule[]
}

export type UploadFile = RefineUploadFile &
  Partial<Document> & {
    exists: boolean
    toDelete?: boolean
  }

export enum RoleEnum {
  user = 'ROLE_USER',
  admin = 'ROLE_ADMIN',
}
export type ExtendedUser = Omit<User, 'roles' | 'client'> & {
  roles: RoleEnum[]
  client?: Client
}

export type ExtendedLog = Omit<Log, 'json'> & {
  json: { [key: string]: { old: string; new: string } }[]
}
