/* eslint-disable */
// @ts-nocheck
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconStarFilled = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.4589 8.92501L15.5081 8.06017L12.8479 2.6672C12.7753 2.51954 12.6557 2.40001 12.5081 2.32735C12.1378 2.14454 11.6878 2.29688 11.5026 2.6672L8.84246 8.06017L2.89168 8.92501C2.72762 8.94845 2.57762 9.02579 2.46278 9.14298C2.32394 9.28568 2.24743 9.47767 2.25007 9.67675C2.2527 9.87583 2.33427 10.0657 2.47684 10.2047L6.78231 14.4024L5.76512 20.3297C5.74127 20.4676 5.75652 20.6094 5.80916 20.739C5.8618 20.8687 5.94972 20.981 6.06294 21.0632C6.17616 21.1455 6.31015 21.1943 6.44973 21.2043C6.5893 21.2142 6.72888 21.1849 6.85262 21.1195L12.1753 18.3211L17.4979 21.1195C17.6432 21.1969 17.812 21.2227 17.9737 21.1945C18.3815 21.1242 18.6557 20.7375 18.5854 20.3297L17.5682 14.4024L21.8737 10.2047C21.9909 10.0899 22.0682 9.93985 22.0917 9.77579C22.155 9.36563 21.869 8.98595 21.4589 8.92501Z"
      fill="url(#paint0_linear_5828_4395)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5828_4395"
        x1={2.26523}
        y1={12.1587}
        x2={22.07}
        y2={12.1587}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9D43B" />
        <stop offset={0.49} stopColor="#E7473F" />
        <stop offset={1} stopColor="#83286B" />
      </linearGradient>
    </defs>
  </svg>
)

const Memo = memo(IconStarFilled)
export default Memo
