import type { AccessControlProvider as IAccessControlProvider } from '@pankod/refine-core'
import type { HydraId } from 'src/adapters/DataProvider'
import { ResourcePathEnum } from 'src/types/api'
import { RoleEnum } from 'src/types/api/extendedTypes'

type AteCanParams =
  | {
      resource: ResourcePathEnum
      action: 'create'
      params?: undefined
    }
  | {
      resource: ResourcePathEnum
      action: 'edit'
      params?: { id: HydraId }
    }
  | {
      resource: ResourcePathEnum
      action: 'show'
      params?: { id: HydraId }
    }
  | {
      resource: ResourcePathEnum
      action: 'delete'
      params?: { id: HydraId }
    }
  | {
      resource: ResourcePathEnum | 'dashboard'
      action: string
      params?: any
    }

type AccessControlProviderParams = {
  getPermissions: () => Promise<RoleEnum[]>
}

const ClientAccessResources = [
  ResourcePathEnum.dispensers,
  ResourcePathEnum.items,
  ResourcePathEnum.promotions,
]

export function AccessControlProvider(
  options: AccessControlProviderParams,
): IAccessControlProvider {
  const { getPermissions } = options

  return {
    async can(canParams) {
      const typedParams = canParams as AteCanParams
      const roles = await getPermissions()
      if (!roles.length) {
        return {
          can: false,
          reason: 'You do not have access to this interface',
        }
      }
      if (roles.includes(RoleEnum.admin)) {
        return { can: true }
      } else {
        if (typedParams.resource === 'dashboard') return { can: true }
        if (typedParams.action === 'list') {
          return { can: false, reason: 'you cannot access list' }
        }
        if (ClientAccessResources.includes(typedParams.resource)) {
          return { can: true }
        }
        return { can: false, reason: 'you cannot have anything' }
      }
    },
  }
}
