import { useForm, Create, Form, Input } from '@pankod/refine-antd'
import { useState } from 'react'
import { v4 as uuid } from 'uuid'

import { FormItem } from '../../components/FormItem'
import type { Ad } from '../../types/api'

import { AdForm } from './AdForm'

export function CreateView() {
  const { formProps, saveButtonProps } = useForm<Ad>()

  const [id] = useState(() => {
    return uuid()
  })

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          id,
        }}
      >
        <FormItem label="id" name="id" hidden>
          <Input />
        </FormItem>
        <AdForm id={id} />
      </Form>
    </Create>
  )
}
