import { DeleteButton, List, Space, Table, useTable } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { cleanHydraId } from 'src/adapters/DataProvider'
import { DateField } from 'src/components/DateField'
import { ReferenceLinkField } from 'src/components/ReferenceField'
import type { Dispenser, Report } from 'src/types/api'
import { ResourcePathEnum } from 'src/types/api'

export function ReportList() {
  const translate = useTranslate()

  const {
    tableProps,
    tableQueryResult: { refetch },
  } = useTable<Report>({
    resource: ResourcePathEnum.reports,
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
  })

  return (
    <List>
      <Table<Report> {...tableProps} rowKey="id">
        <Table.Column
          dataIndex={['user', 'pseudo']}
          title={translate('users.fields.pseudo')}
        />
        <Table.Column
          dataIndex="createdAt"
          title={translate('reports.fields.createdAt')}
          render={(value) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex="dispenser"
          title={translate('reports.fields.dispenser')}
          render={(dispenserId) => {
            return (
              <ReferenceLinkField<Dispenser>
                resourceName="dispensers"
                recordItemId={cleanHydraId(dispenserId)!}
                render={(dispenser) => dispenser.name}
                link="show"
              />
            )
          }}
        />
        <Table.Column
          dataIndex={['rating', 'comment']}
          title={translate('reports.fields.rating')}
        />
        <Table.Column
          dataIndex="reason"
          title={translate('reports.fields.reason')}
        />
        <Table.Column<Report>
          dataIndex="actions"
          render={(_, report) => {
            return (
              <Space>
                <DeleteButton
                  recordItemId={report.id}
                  resource={ResourcePathEnum.reports}
                >
                  {translate('reports.buttons.delete.report')}
                </DeleteButton>
                <DeleteButton
                  recordItemId={report.rating.id}
                  resource={ResourcePathEnum.ratings}
                  onSuccess={() => {
                    void refetch()
                  }}
                >
                  {translate('reports.buttons.delete.rating')}
                </DeleteButton>
              </Space>
            )
          }}
        />
      </Table>
    </List>
  )
}
