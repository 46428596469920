import {
  useForm,
  Edit,
  Form,
  ListButton,
  RefreshButton,
} from '@pankod/refine-antd'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { cleanHydraId } from 'src/adapters/DataProvider'
import type { Promotion } from 'src/types/api'

import { PromotionForm } from './PromotionForm'

export function EditView() {
  const navigate = useNavigate()
  const { formProps, saveButtonProps, queryResult } = useForm<Promotion>({
    redirect: false,
    onMutationSuccess() {
      navigate(-1)
    },
  })

  const itemId =
    queryResult?.data?.data.item && cleanHydraId(queryResult?.data?.data.item)

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      deleteButtonProps={{
        onSuccess() {
          navigate(-1)
        },
      }}
      headerProps={{
        extra: (
          <>
            {itemId ? (
              <Link to={`/items/edit/${itemId}`}>
                <ListButton onClick={undefined} />
              </Link>
            ) : (
              <ListButton onClick={undefined} loading />
            )}
            <RefreshButton />
          </>
        ),
      }}
    >
      <Form {...formProps} layout="vertical">
        <PromotionForm />
      </Form>
    </Edit>
  )
}
