import { useForm, Create, Form } from '@pankod/refine-antd'
import { useNavigate } from 'react-router'
import { toHydraId } from 'src/adapters/DataProvider'
import { FormItem } from 'src/components/FormItem'
import { useParsedLocation } from 'src/libs/useParsedLocation'
import type { Item } from 'src/types/api'
import { ResourcePathEnum } from 'src/types/api'
import { useMemoOne } from 'use-memo-one'
import { v4 as uuidv4 } from 'uuid'

import { ItemForm } from './ItemForm'

export function CreateView() {
  const navigate = useNavigate()
  const { query } = useParsedLocation<{ dispenser: string }>()
  const { dispenser } = query

  if (!dispenser) throw new Error('pages.error.dispenserMissing')

  const { formProps, saveButtonProps } = useForm<Item>({
    redirect: false,
    onMutationSuccess() {
      navigate(`/dispensers/show/${dispenser}`, { replace: true })
    },
  })

  const uuid = useMemoOne(() => {
    return uuidv4()
  }, [])

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          id: uuid,
          dispenser:
            dispenser && toHydraId(ResourcePathEnum.dispensers, dispenser),
        }}
      >
        <FormItem label="id" name="id" hidden />
        <ItemForm id={uuid} />
      </Form>
    </Create>
  )
}
