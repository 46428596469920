import type { ProfunctorState } from '@staltz/use-profunctor-state'
import L from 'leaflet'
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { useCallback, useRef, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import {
  MapContainer,
  TileLayer,
  Marker,
  useMapEvent,
  useMap,
} from 'react-leaflet'
import { v4 as uuid } from 'uuid'

import { useGeoCoding } from '../libs/useGeoCoding'

import 'leaflet/dist/leaflet.css'

export type Position = {
  id: string
  latitude: number
  longitude: number
  postalCode?: string
  fullAddress?: string
  city?: string
  isLoading?: boolean
  exists: boolean
}

export type PositionMap<T extends Position = Position> = Record<string, T>

export const MarkerIcon = L.icon({
  iconUrl: '/Marker.png',
  iconSize: [64, 64],
  iconAnchor: [32, 48],
})

type MapProps = {
  center?: [number, number]
}
export function Map(props: PropsWithChildren<MapProps>) {
  const { children, center } = props
  return (
    <ErrorBoundary
      fallbackRender={(report) => {
        const { message } = report.error
        if (message.includes('Map container is already initialized')) {
          setTimeout(() => report.resetErrorBoundary())
          return null
        }

        throw report.error
      }}
    >
      <MapContainer
        style={{ height: '100%' }}
        center={center ?? [47.747, 7.339]}
        zoom={15}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {children}
      </MapContainer>
    </ErrorBoundary>
  )
}

type PositionsMapProps = {
  maxMarker?: number
  positionsStore: ProfunctorState<PositionMap>
  selectedPosition?: keyof PositionMap
  setSelectedPosition?: Dispatch<SetStateAction<keyof PositionMap | undefined>>
}
export function PositionsMap(props: PositionsMapProps) {
  const { positionsStore, setSelectedPosition } = props
  const { state: positions } = positionsStore

  return (
    <Map>
      <SelectPositionFlyTo {...props} />
      <MarkersWrapper {...props}>
        {Object.values(positions).map((position) => (
          <PositionMarker
            key={position.id}
            positionId={position.id}
            setSelectedPosition={setSelectedPosition}
            positionsStore={positionsStore}
          />
        ))}
      </MarkersWrapper>
    </Map>
  )
}

type SelectPositionFlyToProps = Pick<
  PositionsMapProps,
  'selectedPosition' | 'positionsStore'
>
function SelectPositionFlyTo(props: SelectPositionFlyToProps) {
  const { selectedPosition, positionsStore } = props
  const { state: positions } = positionsStore

  const map = useMap()

  useEffect(() => {
    if (selectedPosition && map) {
      map.flyTo([
        positions[selectedPosition].latitude,
        positions[selectedPosition].longitude,
      ])
    }
  }, [selectedPosition, map, positions])

  return null
}

function MarkersWrapper(props: PropsWithChildren<PositionsMapProps>) {
  const { positionsStore, maxMarker = 1, setSelectedPosition, children } = props
  const { state: positions, setState: setPositions } = positionsStore

  const { reverse } = useGeoCoding()

  async function getAddress(coords: L.LatLng) {
    const { lat, lng } = coords

    const res = await reverse({ lat, lon: lng })
    const adresse = res.data.features[0]?.properties
    if (adresse) {
      return {
        city: adresse.city,
        postalCode: adresse.postcode,
        fullAddress: adresse.label,
      }
    } else {
      return {
        city: '',
        postalCode: '',
        fullAddress: '',
      }
    }
  }

  useMapEvent('click', async (event) => {
    if (maxMarker === 1 && Object.values(positions).length === 1) {
      const { latlng } = event
      const { lat, lng } = latlng

      setPositions((prevPositions) => {
        const position = Object.values(prevPositions)[0]
        return {
          [position.id]: {
            ...position,
            latitude: lat,
            longitude: lng,
            isLoading: true,
          },
        }
      })

      const address = await getAddress(latlng)

      setPositions((prevPositions) => {
        const position = Object.values(prevPositions)[0]
        if (position.latitude === lat && position.longitude === lng) {
          return {
            [position.id]: { ...position, ...address, isLoading: false },
          }
        }
        return prevPositions
      })
    }

    if (Object.values(positions).length > maxMarker - 1) return

    const { latlng } = event
    const { lat, lng } = latlng

    const newPosition: Position = {
      id: uuid(),
      latitude: lat,
      longitude: lng,
      exists: false,
      isLoading: true,
    }
    setPositions((prevPositions) => {
      return { ...prevPositions, [newPosition.id]: newPosition }
    })
    setSelectedPosition?.(newPosition.id)

    const updatedPosition = {
      ...newPosition,
      isLoading: false,
      ...(await getAddress(latlng)),
    }
    setPositions((prevPositions) => {
      return { ...prevPositions, [newPosition.id]: updatedPosition }
    })
  })

  return <>{children}</>
}

type PositionMarkerProps = {
  positionId: string
  setSelectedPosition?: Dispatch<SetStateAction<string | undefined>>
  positionsStore: ProfunctorState<PositionMap>
}

function PositionMarker(props: PositionMarkerProps) {
  const { positionId, setSelectedPosition, positionsStore } = props

  const { state: position, setState: setPosition } = positionsStore.promap(
    (state) => state[positionId],
    (newPosition, state) => {
      return {
        ...state,
        [positionId]: newPosition,
      }
    },
  )

  const markerRef = useRef<L.Marker>(null)
  const { reverse } = useGeoCoding()

  const dragend = useCallback(async () => {
    const marker = markerRef.current
    if (marker) {
      const { lat, lng } = marker.getLatLng()
      setPosition((prevPosition) => {
        return {
          ...prevPosition,
          latitude: lat,
          longitude: lng,
          isLoading: true,
        }
      })

      const res = await reverse({ lat, lon: lng })
      const adresse = res.data.features[0]?.properties
      if (adresse) {
        setPosition((prevPosition) => {
          return {
            ...prevPosition,
            latitude: lat,
            longitude: lng,
            city: adresse.city,
            postalCode: adresse.postcode,
            fullAddress: adresse.label,
            isLoading: false,
          }
        })
      } else {
        setPosition((prevPosition) => {
          return {
            ...prevPosition,
            latitude: lat,
            longitude: lng,
            isLoading: false,
            city: '',
            postalCode: '',
            fullAddress: '',
          }
        })
      }
    }
  }, [reverse, setPosition])

  return (
    <Marker
      key={position.id}
      ref={markerRef}
      position={[position.latitude, position.longitude]}
      icon={MarkerIcon}
      draggable={!position.isLoading}
      eventHandlers={{
        click() {
          if (setSelectedPosition) {
            setSelectedPosition(undefined) // To trigger the flyTo animation even if the position was already selected
            setSelectedPosition(position.id)
          }
        },
        dragend,
      }}
    />
  )
}
