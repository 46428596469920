import { Skeleton, AntdLayout } from '@pankod/refine-antd'
import type { LayoutProps } from '@pankod/refine-core'
import type { PropsWithChildren } from 'react'
import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { AcceptLegalTextModal } from 'src/components/AcceptLegalTextModal'
import useMediaQuery from 'use-media-antd-query'

import { ErrorPage } from './ErrorPage'
import './Layout.less'

export function Layout(props: PropsWithChildren<LayoutProps>) {
  const { children, Sider, Footer, Header, OffLayoutArea } = props

  const isSmallLayout = ['xs', 'sm'].includes(useMediaQuery())

  return (
    <AntdLayout className="AteSidebarLayout">
      {Sider && <Sider />}
      <AntdLayout>
        {Header && <Header />}
        <AntdLayout.Content className="AteLayoutContent">
          <div
            style={{
              paddingInline: isSmallLayout ? 8 : 24,
              paddingBlock: 24,
              minHeight: 360,
              paddingBottom: 64,
            }}
          >
            <Suspense fallback={<Skeleton />}>
              <ErrorBoundary FallbackComponent={ErrorPage}>
                {children}
              </ErrorBoundary>
            </Suspense>
          </div>
          {Footer && <Footer />}
        </AntdLayout.Content>
      </AntdLayout>
      {OffLayoutArea && <OffLayoutArea />}
      <AcceptLegalTextModal />
    </AntdLayout>
  )
}
