import axios from 'axios'

type AdresseApiReturn = {
  features: AdresseFeature[]
}

type AdresseFeature = {
  properties: FeatureProperties
}

type FeatureProperties = {
  label: string
  postcode: string
  city: string
}

export function useGeoCoding() {
  const adresseApiAxios = axios.create({
    baseURL: 'https://api-adresse.data.gouv.fr',
  })
  return {
    async search(query: string) {
      return adresseApiAxios.get<AdresseApiReturn>('/search', {
        params: {
          q: query,
        },
      })
    },
    async reverse(query: { lat: number; lon: number }) {
      return adresseApiAxios.get<AdresseApiReturn>('/reverse', {
        params: query,
      })
    },
  }
}
