import { useForm, Edit, Form, Input } from '@pankod/refine-antd'
import type { Config } from 'src/types/api'

export function EditView() {
  const { formProps, saveButtonProps } = useForm<Config>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label="Title" name="title">
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  )
}
