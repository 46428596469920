import { useForm, Edit, Form } from '@pankod/refine-antd'
import type { ResourceRouterParams } from '@pankod/refine-core'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { useEnv } from 'src/adapters/Env'
import { hydrateDocuments } from 'src/components/DocumentUpload'
import type { Ad } from 'src/types/api'

import { AdForm } from './AdForm'

export function EditView() {
  const env = useEnv()
  const { id } = useParams<ResourceRouterParams>()
  if (!id) {
    throw new Error('pages.error.404')
  }
  const { formProps, saveButtonProps, queryResult, form } = useForm<Ad>()

  useEffect(() => {
    if (queryResult?.isFetched) {
      const ad = queryResult?.data?.data

      form.setFieldsValue({
        ...ad,
        document: hydrateDocuments(
          ad?.document ? [ad.document] : [],
          env.API_URL,
        ),
      })
    }
  }, [queryResult?.data?.data, form, env.API_URL, queryResult?.isFetched])

  const ad = queryResult?.data?.data

  const mapCenter = useMemo(() => {
    if (ad?.latitude && ad?.longitude) {
      return [ad.latitude, ad.longitude] as [number, number]
    }
  }, [ad?.latitude, ad?.longitude])

  return (
    <Edit saveButtonProps={saveButtonProps} isLoading={queryResult?.isLoading}>
      <Form {...formProps} layout="vertical">
        {ad && <AdForm id={id} center={mapCenter} />}
      </Form>
    </Edit>
  )
}
