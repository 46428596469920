import { Button, Icons } from '@pankod/refine-antd'
import { useQueryClient, useTranslate, useUpdate } from '@pankod/refine-core'
import type { ResourcePathEnum } from 'src/types/api'

type Props<T> = {
  resource: ResourcePathEnum | string
  record: T
  disableProp: keyof T
  isDisabled: boolean
}

type Record = {
  id: string
  [key: string]: any
}

export function ToggleEnabledButton<T extends Record>(props: Props<T>) {
  const { resource, record, disableProp, isDisabled } = props

  const translate = useTranslate()
  const { mutate, isLoading } = useUpdate()
  const pending = isLoading
  const queryClient = useQueryClient()

  function onClick() {
    mutate(
      {
        resource,
        id: record.id,
        values: {
          [disableProp]: !record[disableProp],
        },
      },
      {
        async onSuccess() {
          return queryClient.invalidateQueries()
        },
      },
    )
  }

  return (
    <Button
      onClick={onClick}
      loading={pending}
      danger={!isDisabled}
      icon={isDisabled ? <Icons.CheckOutlined /> : <Icons.StopOutlined />}
    >
      {translate(isDisabled ? 'buttons.activate' : 'buttons.disable')}
    </Button>
  )
}
