import { Typography } from '@pankod/refine-antd'
import { useGetLocale } from '@pankod/refine-core'
import type { ConfigType } from 'dayjs'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(LocalizedFormat)

type DateFieldProps = {
  format?: string
  value: ConfigType
}

/**
 * This field is used to display dates. It uses {@link https://day.js.org/docs/en/display/format `Day.js`} to display date format.
 *
 * @see {@link https://refine.dev/docs/api-references/components/fields/date} for more details.
 */
export function DateField(props: DateFieldProps) {
  const { value, format: dateFormat = 'L' } = props
  const getLocale = useGetLocale()

  return (
    <Typography.Text>
      {dayjs(value)
        .locale(getLocale() ?? 'fr')
        .format(dateFormat)}
    </Typography.Text>
  )
}
