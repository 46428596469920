import type { TitleProps } from '@pankod/refine-core'
import { Link } from 'react-router-dom'

import logoImg from './theme/logo.svg'
import pictoImg from './theme/picto.svg'

export function Title(titleProps: TitleProps) {
  const { collapsed } = titleProps
  return (
    <Link to="/">
      <img
        src={collapsed ? pictoImg : logoImg}
        alt="Alltime Eat Logo"
        style={{
          width: '100%',
          minHeight: collapsed ? '80px' : '200px',
        }}
      />
    </Link>
  )
}
