import {
  Checkbox,
  Form,
  Modal,
  Typography,
  Space,
  Button,
} from '@pankod/refine-antd'
import {
  useGetIdentity,
  useTranslate,
  useUpdate,
  useOne,
} from '@pankod/refine-core'
import { Trans } from 'react-i18next'
import { useEnv } from 'src/adapters/Env'
import type { UserIdentity } from 'src/Auth'
import { ResourcePathEnum } from 'src/types/api'
import type { ExtendedUser } from 'src/types/api/extendedTypes'

export function AcceptLegalTextModal() {
  const translate = useTranslate()
  const env = useEnv()
  const identity = useGetIdentity<UserIdentity>()

  const userId = identity.data?.me.id

  const { data, isLoading, refetch } = useOne<ExtendedUser>({
    resource: ResourcePathEnum.users,
    id: userId!,
    queryOptions: {
      enabled: !!userId,
    },
  })

  const user = data?.data

  const { mutate, isLoading: updateLoading } = useUpdate()

  if (!user || user.cguAgreed) return null

  return (
    <Modal
      closable={false}
      maskClosable={false}
      centered
      visible={!user.cguAgreed}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={() => {
          mutate(
            {
              resource: ResourcePathEnum.users,
              id: user.id,
              values: {
                cguAgreed: true,
              },
            },
            {
              async onSuccess() {
                return refetch()
              },
            },
          )
        }}
      >
        <Typography.Title level={3}>
          {translate('modal.acceptTerms.label')}
        </Typography.Title>
        <Form.Item
          name="terms"
          valuePropName="checked"
          rules={[
            {
              validator(_, value) {
                if (value) return Promise.resolve()
                return Promise.reject(
                  new Error(translate('validation.acceptAgreement')),
                )
              },
            },
          ]}
        >
          <Checkbox>
            <Trans
              i18nKey="pages.auth.acceptTerms"
              components={{
                cgu: <a href={env.CGU_URL} target="_blank" rel="noreferrer" />,
                cgv: <a href={env.CGV_URL} target="_blank" rel="noreferrer" />,
                terms: (
                  <a href={env.TERMS_URL} target="_blank" rel="noreferrer" />
                ),
              }}
            />
          </Checkbox>
        </Form.Item>
        <Space style={{ width: '100%', justifyContent: 'center' }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading || updateLoading}
          >
            {translate('buttons.validate')}
          </Button>
        </Space>
      </Form>
    </Modal>
  )
}
