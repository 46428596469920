import { Skeleton } from '@pankod/refine-antd'
import { useOne } from '@pankod/refine-core'
import type { BaseRecord } from '@pankod/refine-core'

import { TypographyLink } from './TypographyLink'

type ReferenceFieldProps<T> = {
  resourceName: string
  recordItemId: string
  render: (value: T) => any
  suspense?: boolean
  link?: 'show' | 'edit'
}
export function ReferenceField<T extends BaseRecord>(
  props: ReferenceFieldProps<T>,
) {
  const { resourceName, recordItemId, render, suspense } = props
  const { data, isLoading } = useOne<T>({
    resource: resourceName,
    id: recordItemId,
    queryOptions: {
      suspense,
    },
  })

  if (isLoading) return <Skeleton active paragraph={false} />

  if (!data?.data) return null
  return <>{render(data.data)}</>
}

export function ReferenceLinkField<T extends BaseRecord>(
  props: ReferenceFieldProps<T>,
) {
  const { resourceName, recordItemId, link = 'show', render } = props

  return (
    <ReferenceField<T>
      {...props}
      render={(data) => (
        <TypographyLink to={`/${resourceName}/${link}/${recordItemId}`}>
          {render(data)}
        </TypographyLink>
      )}
    />
  )
}
