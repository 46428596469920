import {
  DeleteButton,
  EmailField,
  List,
  Space,
  Table,
  useTable,
} from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useSearch } from 'src/libs/useSearch'
import { ResourcePathEnum } from 'src/types/api'
import type { ExtendedUser } from 'src/types/api/extendedTypes'

export function MobileUserListView() {
  const translate = useTranslate()

  const { queryFilter, listProps } = useSearch('email')
  const { tableProps } = useTable<ExtendedUser>({
    resource: ResourcePathEnum.users,
    permanentFilter: [
      ...queryFilter,
      { field: 'isMobileUser', operator: 'eq', value: true },
    ],
    initialSorter: [
      {
        field: 'pseudo',
        order: 'asc',
      },
    ],
  })

  return (
    <List {...listProps}>
      <Table<ExtendedUser>
        {...tableProps}
        rowKey="id"
        rowClassName={(user) => {
          if (user.client && !user.client.isActive) {
            return 'AteTableRow -disabled'
          }
          return 'AteTableRow'
        }}
      >
        <Table.Column
          dataIndex="pseudo"
          title={translate('users.fields.pseudo')}
        />

        <Table.Column<ExtendedUser>
          dataIndex="email"
          title={translate('users.fields.email')}
          render={(_, user) => {
            return <EmailField value={user?.email} />
          }}
        />

        <Table.Column<ExtendedUser>
          dataIndex="actions"
          render={(_, record) => {
            return (
              <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
                <DeleteButton
                  resource={ResourcePathEnum.users}
                  recordItemId={record?.id}
                />
              </Space>
            )
          }}
        />
      </Table>
    </List>
  )
}
