import * as Sentry from '@sentry/react'
import { filterConsole } from 'src/libs/filterConsole'

import type { IEnv } from './adapters/Env'
import { config } from './config'

export function setup(env: IEnv) {
  if (env.IS_DEV) {
    console.info('Setting up app with following env config:', env)
  }

  if (!env.IS_DEV) {
    Sentry.init({
      dsn: env.SENTRY_DSN,
      environment: env.APP_ENV,
      release: config.APP_RELEASE,
    })
  }

  addWebpackOverlayDismissShortcut()

  // Use this util to remove warnings when they happen inside a dependency sources
  // Use `console.noFilter()` to print all warnings again, and keep original log location
  ;(window.console as any).noFilter = filterConsole(
    [
      'Warning: findDOMNode is deprecated in StrictMode', // from antd <Image /> component
      'findDOMNode is deprecated and will be removed in the next major release.', // from antd <Image /> component
      'Support for defaultProps will be removed from function components in a future major release. Use JavaScript default parameters instead.', // we would have to upgrade antdesign
      '[antd: Menu] `children` will be removed in next major version. Please use `items` instead.', // we will fix it later
      `Duplicated key 'undefined' used in Menu by path []`, // linked to the previous issue
    ],
    {
      methods: ['warn', 'error'],
    },
  )
}

function addWebpackOverlayDismissShortcut() {
  if (typeof window === 'undefined') return
  if (process.env.NODE_ENV !== 'development') return

  window.addEventListener(
    'keydown',
    (event) => {
      if (event.key === 'Escape') {
        const overlayIframe = document.querySelector(
          '#webpack-dev-server-client-overlay',
        ) as HTMLIFrameElement | null

        const dismissButton = overlayIframe?.contentDocument
          ?.querySelector('#webpack-dev-server-client-overlay-div')
          ?.querySelector(
            'button[aria-label=Dismiss]',
          ) as HTMLButtonElement | null

        dismissButton?.click()
      }
    },
    { capture: true },
  )
}
