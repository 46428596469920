import { type FormItemProps, Form, Input } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'

type Props<T> = Omit<FormItemProps<T>, 'label'> & {
  label?: string
}
export function FormItem<T>(props: Props<T>) {
  const { label, hidden, children } = props
  const translate = useTranslate()
  return (
    <Form.Item
      {...props}
      hidden={hidden}
      rules={[
        {
          required: props.required,
          message: translate('validation.required'),
        },
        ...(props.rules ?? []),
      ]}
      label={label && translate(label)}
    >
      {hidden ? <Input type="hidden" /> : children}
    </Form.Item>
  )
}
