import {
  Card,
  Typography,
  Spin,
  Form,
  Checkbox,
  Button,
  Space,
  notification,
} from '@pankod/refine-antd'
import { useGetIdentity } from '@pankod/refine-core'
import {
  useMutation,
  QueryClientProvider,
  QueryClient,
} from '@tanstack/react-query'
import { getAuth } from 'firebase/auth'
import jwtDecode from 'jwt-decode'
import type { PropsWithChildren } from 'react'
import { useState, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import type { RouteProps } from 'react-router'
import { useEnv } from 'src/adapters/Env'
import { useParsedLocation } from 'src/libs/useParsedLocation'

import {
  HTTPClient,
  HttpClientContext,
  useHttpClient,
} from '../adapters/HTTPClient'
import logoImg from '../UI/theme/logo.svg'

import { isValidToken } from './AuthContext'
import type { DecodedApiToken } from './AuthContext'
import type { UserIdentity } from './AuthProvider'
import { LoginPage } from './LoginPage'

import './index.less'

type FormState = {
  cgu: boolean
  terms: boolean
}

function DeleteMeForm(props: { userId: string }) {
  const { userId } = props
  const auth = getAuth()
  const env = useEnv()

  const { t: translate } = useTranslation()

  const httpClient = useHttpClient()
  const { mutate, isLoading: deleteLoading } = useMutation(
    () => {
      return httpClient.delete(`/users/${userId}`)
    },
    {
      onSuccess() {
        notification.open({
          type: 'success',
          message: translate('notifications.deleteMe'),
        })
        return auth.signOut()
      },
    },
  )

  const [formState, setFormState] = useState<FormState>({
    cgu: false,
    terms: false,
  })

  if (!userId) return null

  function getFormStateController(name: keyof FormState) {
    return {
      checked: formState[name],
      onChange() {
        setFormState((oldFormState) => {
          return {
            ...oldFormState,
            [name]: !oldFormState[name],
          }
        })
      },
    }
  }
  return (
    <Card className="AteAuthCard">
      <Typography.Title level={4}>
        {translate('pages.auth.delete.title')}
      </Typography.Title>
      <Typography.Paragraph>
        <Trans
          i18nKey="pages.auth.delete.description"
          components={{
            cgu: <a href={env.CGU_URL} target="_blank" rel="noreferrer" />,
            terms: <a href={env.TERMS_URL} target="_blank" rel="noreferrer" />,
          }}
        />
      </Typography.Paragraph>
      <Typography.Paragraph>
        {translate('pages.auth.delete.hint')}
      </Typography.Paragraph>
      <Form
        layout="vertical"
        onFinish={() => {
          mutate()
        }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Checkbox {...getFormStateController('terms')}>
            <Trans
              i18nKey="pages.auth.delete.inputs.terms"
              components={{
                terms: (
                  <a href={env.TERMS_URL} target="_blank" rel="noreferrer" />
                ),
              }}
            />
          </Checkbox>
          <Checkbox {...getFormStateController('cgu')}>
            <Trans
              i18nKey="pages.auth.delete.inputs.cgu"
              components={{
                cgu: <a href={env.CGU_URL} target="_blank" rel="noreferrer" />,
              }}
            />
          </Checkbox>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={deleteLoading}
              disabled={Object.values(formState).some((value) => !value)}
            >
              {translate('pages.auth.delete.button')}
            </Button>
          </div>
        </Space>
      </Form>
    </Card>
  )
}

function DeleteMePageLayout(props: PropsWithChildren<Record<string, unknown>>) {
  return (
    <section className="AteAuthPage">
      <img src={logoImg} className="AteAuthPageLogo" />

      {props.children}
    </section>
  )
}

function AuthenticatedDeleteMePage() {
  const { data, isLoading } = useGetIdentity<UserIdentity>()
  const userId = data?.me.id

  if (isLoading) {
    return (
      <div className="AteSignupLoading">
        <Spin />
      </div>
    )
  }

  return <DeleteMeForm userId={userId!} />
}

export function TokenDeleteMePage() {
  const { query } = useParsedLocation<{ token: string }>()
  const { token } = query
  const env = useEnv()

  const httpClient = useMemo(
    () =>
      HTTPClient({
        getAccessToken() {
          return Promise.resolve(token)
        },
        baseURL: env.API_URL,
      }),
    [env.API_URL, token],
  )

  const [queryClient] = useState(() => new QueryClient())

  if (!token || !isValidToken(token)) {
    return <LoginPage />
  }

  const { user_id } = jwtDecode<DecodedApiToken>(token)

  return (
    <QueryClientProvider client={queryClient}>
      <HttpClientContext.Provider value={httpClient}>
        <DeleteMePageLayout>
          <DeleteMeForm userId={user_id} />
        </DeleteMePageLayout>
      </HttpClientContext.Provider>
    </QueryClientProvider>
  )
}

export const deleteMeRoute: RouteProps = {
  path: '/deleteMe',
  element: (
    <DeleteMePageLayout>
      <AuthenticatedDeleteMePage />
    </DeleteMePageLayout>
  ),
}
