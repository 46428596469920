import { Input } from '@pankod/refine-antd'
import type { CrudFilters } from '@pankod/refine-core'
import '@pankod/refine-core'
import { useState } from 'react'
import { useDebounce } from 'use-debounce'

export function useSearch(searchParam: string = 'q') {
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 100)

  return {
    queryFilter: [
      {
        field: searchParam,
        operator: 'eq',
        value: debouncedSearch,
      },
    ] as CrudFilters,
    listProps: {
      pageHeaderProps: {
        subTitle: (
          <Input.Search
            allowClear
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        ),
      },
    },
  }
}
