import {
  Button,
  Form,
  Icons,
  Space,
  TimePicker as RefineTimePicker,
} from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import type { ProfunctorState } from '@staltz/use-profunctor-state'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useMemo } from 'react'
import { DispenserScheduleWeekdayEnum } from 'src/types/api'
import { v4 as uuid } from 'uuid'

dayjs.extend(utc)

export type Schedule = {
  id: string
  weekday: DispenserScheduleWeekdayEnum
  start: number
  end: number
  exists: boolean
  touched?: boolean
}

type ScheduleMap = { [key in DispenserScheduleWeekdayEnum]?: Schedule[] }

type Props = {
  scheduleStore: ProfunctorState<Schedule[]>
}

const SORTED_WEEKDAY = [
  DispenserScheduleWeekdayEnum.monday,
  DispenserScheduleWeekdayEnum.tuesday,
  DispenserScheduleWeekdayEnum.wednesday,
  DispenserScheduleWeekdayEnum.thursday,
  DispenserScheduleWeekdayEnum.friday,
  DispenserScheduleWeekdayEnum.saturday,
  DispenserScheduleWeekdayEnum.sunday,
]

const TIME_FORMAT = 'HH : mm'

export function ScheduleForm(props: Props) {
  const translate = useTranslate()
  const { scheduleStore } = props
  const { state: schedules, setState: setSchedules } = scheduleStore

  const scheduleMap = useMemo(() => {
    const result: ScheduleMap = {}

    schedules.forEach((schedule) => {
      result[schedule.weekday] = [...(result[schedule.weekday] ?? []), schedule]
    })
    return result
  }, [schedules])

  function onChange(newSchedule: Schedule) {
    setSchedules((prevSchedules) =>
      prevSchedules.map((schedule) => {
        if (schedule.id === newSchedule.id) {
          return { ...newSchedule, touched: true }
        }
        return schedule
      }),
    )
  }

  function createSchedule(weekday: DispenserScheduleWeekdayEnum) {
    setSchedules((prevSchedules) => {
      return [
        ...prevSchedules,
        { id: uuid(), weekday, start: 0, end: 0, exists: false },
      ]
    })
  }

  function deleteSchedule(id: string) {
    setSchedules((prevSchedules) => {
      return prevSchedules.filter((schedule) => schedule.id !== id)
    })
  }

  return (
    <Form layout="vertical">
      {SORTED_WEEKDAY.map((weekday) => (
        <Form.Item key={weekday} label={translate(`weekday.${weekday}`)}>
          <Space direction="vertical">
            {scheduleMap[weekday]?.map((schedule) => (
              <Space key={schedule.id}>
                <TimePicker
                  value={dayjs.unix(schedule.start).utc()}
                  onChange={(value) => {
                    onChange({
                      ...schedule,
                      start: value ? getHoursInSeconds(value) : 0,
                    })
                  }}
                />
                {translate('dispenserSchedule.to')}
                <TimePicker
                  value={dayjs.unix(schedule.end).utc()}
                  onChange={(value) =>
                    onChange({
                      ...schedule,
                      end: value ? getHoursInSeconds(value) : 0,
                    })
                  }
                />
                <Button
                  danger
                  onClick={(e) => {
                    e.stopPropagation()
                    deleteSchedule(schedule.id)
                  }}
                  icon={<Icons.DeleteOutlined />}
                />
              </Space>
            ))}
            <Button
              icon={<Icons.PlusCircleOutlined />}
              onClick={() => createSchedule(weekday)}
            >
              {translate('dispenserSchedule.addTime')}
            </Button>
          </Space>
        </Form.Item>
      ))}
    </Form>
  )
}

type TimePickerProps = {
  value: dayjs.Dayjs
  onChange: (value: dayjs.Dayjs | null) => void
}

function TimePicker(props: TimePickerProps) {
  const { value, onChange } = props
  return (
    <RefineTimePicker
      format={TIME_FORMAT}
      value={value}
      minuteStep={15}
      onChange={onChange}
      onSelect={onChange}
    />
  )
}

function getHoursInSeconds(date: dayjs.Dayjs) {
  // Modulo number of seconds in one days
  return date.unix() % (60 * 60 * 24)
}
