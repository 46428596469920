import { useTable, Table, Card } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { DateField } from 'src/components/DateField'
import { ReferenceLinkField } from 'src/components/ReferenceField'
import { getResourcePath } from 'src/Dashboard/LatestLogs'
import type { Log, User } from 'src/types/api'
import { ResourcePathEnum } from 'src/types/api'
import type { ExtendedLog } from 'src/types/api/extendedTypes'

type Props = {
  id: string
}

export function DispenserLogs(props: Props) {
  const { id } = props
  const { tableProps } = useTable<ExtendedLog>({
    resource: ResourcePathEnum.logs,
    initialSorter: [{ field: 'createdAt', order: 'desc' }],
    permanentFilter: [
      {
        field: 'dispenser.id',
        operator: 'eq',
        value: id,
      },
    ],
  })
  const translate = useTranslate()

  return (
    <Card title={translate('dispensers.fields.logs')}>
      <Table
        {...tableProps}
        rowKey="id"
        expandable={{
          rowExpandable(log) {
            return log.json.length > 0
          },
          expandedRowRender(log) {
            const logData = log.json
              .map((logObject) =>
                Object.entries(logObject).map(([key, value]) => {
                  return { property: key, ...value }
                }),
              )
              .flat()
            return (
              <Table
                size="small"
                pagination={false}
                dataSource={logData}
                columns={[
                  {
                    title: translate('logs.fields.property'),
                    dataIndex: 'property',
                    key: 'property',
                    render(value) {
                      return translate(`dispensers.fields.${value}`)
                    },
                  },
                  {
                    title: translate('logs.fields.old'),
                    dataIndex: 'old',
                    key: 'old',
                  },
                  {
                    title: translate('logs.fields.new'),
                    dataIndex: 'new',
                    key: 'new',
                  },
                ]}
              />
            )
          },
        }}
      >
        <Table.Column
          dataIndex="createdAt"
          title={translate('logs.fields.createdAt')}
          render={(value) => <DateField format="LLL" value={value} />}
        />
        <Table.Column
          dataIndex="resourceClass"
          title={translate('logs.fields.resource')}
          render={(value) => translate(`${getResourcePath(value)}.singleName`)}
        />
        <Table.Column<Log>
          dataIndex="user"
          title={translate('logs.fields.user')}
          render={(_, log) => {
            const { user } = log

            return (
              <ReferenceLinkField<User>
                resourceName={ResourcePathEnum.users}
                recordItemId={user}
                render={(element) => element.email}
              />
            )
          }}
        />
      </Table>
    </Card>
  )
}
