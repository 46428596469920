import { Typography } from '@pankod/refine-antd'
import type { LinkProps } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'

export function TypographyLink(props: LinkProps) {
  const { children, ...rest } = props

  /*
   * until react-router 6 is available for refine and useLinkClickHandler usable,
   * this hack will do the job
   */
  return (
    <RouterLink {...rest}>
      <object>
        <Typography.Link strong>{children}</Typography.Link>
      </object>
    </RouterLink>
  )
}
