import {
  type StatisticProps,
  CreateButton,
  Spin,
  Skeleton,
  Divider,
  Card,
  Space,
  Typography,
  Statistic,
  Image,
} from '@pankod/refine-antd'
import {
  useMany,
  useGetIdentity,
  useCustom,
  useTranslate,
} from '@pankod/refine-core'
import { List } from 'antd'
import { useMemo, type PropsWithChildren } from 'react'
import { cleanHydraId } from 'src/adapters/DataProvider'
import type { UserIdentity } from 'src/Auth'
import { TypographyLink } from 'src/components/TypographyLink'
import { useDocumentUrl } from 'src/libs/useMinioFile'
import { ResourcePathEnum } from 'src/types/api'
import type {
  ExtendedDispenser,
  ExtendedUser,
} from 'src/types/api/extendedTypes'

import { LatestLogs } from './LatestLogs'

const { Title, Text } = Typography

type ActivityStats = {
  dispensers: number
  items: number
}
type DispenserStats = {
  nbActive: number
  nbInactive: number
}
export function Dashboard() {
  const { data, isLoading } = useGetIdentity<UserIdentity>()

  if (isLoading) return <Spin />

  if (data) {
    if (data?.me.isAdmin) return <AdminDashboard />

    return <ClientDashboard user={data?.me} />
  }
  return null
}

type ClientProps = {
  user: ExtendedUser
}

function ClientDashboard(props: ClientProps) {
  const { user } = props
  const translate = useTranslate()

  const { client } = user
  const dispensersIds = client?.dispensers ?? []

  const { data: dispensers, isLoading } = useMany<ExtendedDispenser>({
    resource: ResourcePathEnum.dispensers,
    ids: dispensersIds,
  })

  return (
    <>
      <Title level={2}>{translate('dashboard.title')}</Title>
      <Card
        title={translate('dispensers.name')}
        extra={
          client &&
          client.dispenserLimit > client.dispensers.length && (
            <CreateButton
              disabled={false}
              onClick={undefined}
              resourceNameOrRouteName={`dispensers`}
            />
          )
        }
      >
        <List loading={isLoading}>
          {dispensers?.data.map((dispenser) => (
            <DispenserItem key={dispenser.id} dispenser={dispenser} />
          ))}
        </List>
      </Card>
    </>
  )
}

type DispenserItemProps = {
  dispenser: ExtendedDispenser
}
function DispenserItem(props: DispenserItemProps) {
  const { dispenser } = props
  const imageUrl = useDocumentUrl(dispenser.documents[0].id)

  const description = useMemo(() => {
    const container = document.createElement('div')
    container.innerHTML = dispenser.description.replaceAll('><', '>\n<')
    return container.textContent
  }, [dispenser.description])

  return (
    <List.Item>
      <List.Item.Meta
        avatar={
          <Image
            src={imageUrl}
            width={64}
            height={64}
            style={{ objectFit: 'cover' }}
          />
        }
        title={
          <TypographyLink
            to={`/${ResourcePathEnum.dispensers}/show/${cleanHydraId(
              dispenser.id,
            )}`}
          >
            {dispenser.name}
          </TypographyLink>
        }
        description={<Text ellipsis>{description}</Text>}
      />
    </List.Item>
  )
}

function AdminDashboard() {
  const translate = useTranslate()

  const { data: activity } = useCustom<ActivityStats>({
    method: 'get',
    url: '/logs/stats',
  })
  const updatedDispensersCount = activity?.data.dispensers
  const updatedItemsCount = activity?.data.items

  const { data: dispenserStats } = useCustom<DispenserStats>({
    method: 'get',
    url: '/dispensers/stats',
  })

  const activeDispensersCount = dispenserStats?.data.nbActive
  const disabledDispensersCount = dispenserStats?.data.nbInactive

  return (
    <>
      <Title level={2}>{translate('dashboard.title')}</Title>

      <Space align="start" size={24} style={{ alignItems: 'stretch' }}>
        <StatCard
          title="dashboard.lastActivity.title"
          subTitle="dashboard.lastActivity.subTitle"
        >
          <LocalizedStat
            title="dashboard.lastActivity.updatedDispensersCount"
            value={updatedDispensersCount}
          />
          <LocalizedStat
            title="dashboard.lastActivity.updatedItemsCount"
            value={updatedItemsCount}
          />
        </StatCard>

        <StatCard title="dashboard.dispensers.title">
          <LocalizedStat
            title="dashboard.dispensers.activeCount"
            value={activeDispensersCount}
          />
          <LocalizedStat
            title="dashboard.dispensers.inactiveCount"
            value={disabledDispensersCount}
          />
        </StatCard>
      </Space>

      <Divider />

      <Card title={translate('dashboard.latestLogs.title')}>
        <LatestLogs />
      </Card>
    </>
  )
}

type StatCardProps = {
  title: string
  subTitle?: string
}

function StatCard(props: PropsWithChildren<StatCardProps>) {
  const { title, subTitle, children } = props
  const translate = useTranslate()

  return (
    <Card
      title={
        <Space direction="vertical" size={0}>
          {translate(title)}
          {subTitle && <Text type="secondary">{translate(subTitle)}</Text>}
        </Space>
      }
      style={{ minWidth: 300 }}
    >
      <Space direction="vertical" size={16}>
        {children}
      </Space>
    </Card>
  )
}

function LocalizedStat(props: StatisticProps) {
  const { title } = props
  const translate = useTranslate()

  return (
    <Statistic
      decimalSeparator=","
      groupSeparator=" "
      {...props}
      value={props.value}
      valueRender={
        props.value !== undefined ? undefined : () => <Skeleton.Button active />
      }
      title={typeof title === 'string' ? translate(title) : title}
    />
  )
}
