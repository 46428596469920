/* eslint-disable */
// @ts-nocheck
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconStarEmpty = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0597 8.28135L15.176 8.51715L15.4362 8.55497L21.3854 9.41958C21.3856 9.41961 21.3858 9.41964 21.386 9.41968C21.5275 9.44104 21.6176 9.56941 21.5975 9.69955L21.5975 9.69955L21.5967 9.70508C21.5891 9.75806 21.5641 9.80784 21.5242 9.84718C21.524 9.84732 21.5239 9.84745 21.5237 9.84759L17.2192 14.0443L17.031 14.2278L17.0754 14.4869L18.0926 20.4143L18.0927 20.4146C18.1159 20.5491 18.0257 20.6782 17.8888 20.7018L17.888 20.7019C17.8336 20.7114 17.7779 20.7021 17.7329 20.6782L17.7306 20.677L12.408 17.8785L12.1753 17.7562L11.9426 17.8785L6.61994 20.677L6.61917 20.6774C6.57805 20.6991 6.53167 20.7089 6.48529 20.7055C6.43891 20.7022 6.39439 20.686 6.35676 20.6587C6.31914 20.6313 6.28993 20.594 6.27244 20.551C6.25495 20.5079 6.24987 20.4607 6.2578 20.4149L6.25792 20.4143L7.2751 14.4869L7.31956 14.2278L7.13135 14.0443L2.82588 9.84669L2.82585 9.84666C2.77818 9.80019 2.7509 9.73669 2.75002 9.67013C2.74914 9.60386 2.77449 9.53994 2.82052 9.49229C2.85983 9.45246 2.90952 9.42754 2.96239 9.41998L2.96359 9.41981L8.91437 8.55497L9.17457 8.51715L9.29088 8.28135L11.9498 2.8908C11.95 2.8905 11.9501 2.8902 11.9503 2.88991C12.0134 2.76484 12.165 2.71558 12.2868 2.7757L12.2873 2.77598C12.336 2.79991 12.3754 2.83932 12.3993 2.88795L12.3995 2.88838L15.0597 8.28135Z"
      stroke="black"
      strokeOpacity={0.2}
    />
  </svg>
)

const Memo = memo(IconStarEmpty)
export default Memo
