import type { AuthProvider as RefineAuthProvider } from '@pankod/refine-core'
import type { AxiosError } from 'axios'
import { getAuth } from 'firebase/auth'
import type { ExtendedUser, RoleEnum } from 'src/types/api/extendedTypes'

import type { User as AuthUser } from './AuthContext'

type Params = {
  loggedUser: AuthUser
  getMe: () => Promise<ExtendedUser>
}

export type UserIdentity = {
  user: AuthUser
  me: ExtendedUser
}

export function AuthProvider(params: Params) {
  const { loggedUser, getMe } = params
  const auth = getAuth()

  return {
    async login() {
      // Implemented in AuthScope
    },

    async logout() {
      return auth.signOut()
    },

    async checkError(error: AxiosError) {
      const LOGGED_OUT_ERROR_MESSAGES = [
        "invalid_request: User session not found or doesn't have client attached on it",
        'invalid_token: Token verification failed',
      ]
      if (LOGGED_OUT_ERROR_MESSAGES.includes(error.response?.data?.detail)) {
        throw error
      }
    },

    async checkAuth() {
      // Implemented in AuthScope
    },

    async getUserIdentity(): Promise<UserIdentity> {
      return {
        user: loggedUser,
        me: await getMe(),
      }
    },

    async getPermissions(): Promise<RoleEnum[]> {
      const me = await getMe()
      if (me.isMobileUser) {
        return []
      }
      return me.roles
    },
  } satisfies RefineAuthProvider
}
